// Vendors
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { Spinner } from '@vismaux/react-vud';
import { useTranslation } from 'react-i18next';
import { useNavigation, useParams } from 'react-router-dom';
import { useToast } from '@context/ToastContext';
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Types
import { LoadingState } from '@appTypes/loadingstates';
import { IPagedRequestPayload } from '@appTypes/discussionthread';
// Services
import FolderService from '@services/FolderService';
// Higher Order Components
import withReplyHandling from '@hoc/withReplyHandling';
// Hooks
import { useUpdateInboxUnreadCount } from '@hooks/useUpdateInboxUnreadCount';
// Components
import DiscussionTitle from '@components/DiscussionTitle';
import DiscussionThread from '@components/DiscussionThread';
import DiscussionThreadReply from '@components/DiscussionThreadReply';
import DiscussionThreadService from '@services/DiscussionThreadService';

const WrappedDiscussionThreadReply = withReplyHandling(DiscussionThreadReply);

const DiscussionThreadContainer = () => {
    const { state: { errorCanceled, activeDiscussionThread, paginationData }, dispatch } = MessagingState();
    const { t } = useTranslation();
    const toast = useToast();
    const { state } = useNavigation();
    const { folderParam, discussionThreadId } = useParams();
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();

    useEffect(() => {
        const fetchDiscussionThread = async () => {
            if (discussionThreadId) {
                try {
                    const discussionThreadContent = await DiscussionThreadService.getDiscussionThread(parseInt(discussionThreadId));
                    if (discussionThreadContent) {
                        const pagedPayload: IPagedRequestPayload = {
                            pageNumber: 1,
                            pageSize: 0
                        };
                        const messageResponse = await DiscussionThreadService.postThreadMessages(discussionThreadContent.id, { messageIds: discussionThreadContent.messages.map(message => message.id) }, pagedPayload);
                        dispatch({
                            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                            payload: { thread: discussionThreadContent, activeThreadMessages: messageResponse.messages, loadingState: LoadingState.Done }
                        });
                    }
                } catch(error) {
                    // refetch and update discussion threads if the selected thread was cancelled
                    if (error instanceof AxiosError) {    
                        if (error.response?.status === 404) {     
                            toast.createToast({
                                title: t('errors.cancelErrors.cancel_5'),
                                toastType: 'info'
                            });
                            const activeFolder = FolderService.getAll().find(folder => folder.path === folderParam);
                            if (activeFolder?.fetchFunction) {
                                try {
                                    const response = await activeFolder.fetchFunction({ pageNumber: paginationData.currentPage, pageSize: 10 }); 
                                    dispatch({
                                        type: ActionType.SET_DISCUSSIONTHREADS,
                                        payload: response.discussionThreads
                                    });
                                    dispatch({
                                        type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                                        payload: { thread: null, loadingState: LoadingState.Done } 
                                    });
                                    dispatch({ type: ActionType.SET_PAGINATION_DATA, payload: response.paginationData });
                                } catch (fetchError) {
                                    console.error('Error fetching discussion threads:', fetchError);
                                }
                            }
                        } else {
                            toast.createToast({
                                title: t('errors.discussionThreadLoadingFailed'),
                                toastType: 'warning'
                            });
                            console.error(error);
                        }
                    } else {
                        console.error(error);
                    }
                }
            }
        };
        fetchDiscussionThread();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discussionThreadId, dispatch, t, updateInboxUnreadCount]);

    return (
        <>

            {errorCanceled && (
                <div className='center'>
                    <span className='error-text'>{errorCanceled ? t('errors.discussionThreadCanceled') : t('errors.discussionThreadDataLoadingFailed')}</span>
                </div>
            )}
            {activeDiscussionThread && state === 'idle' && (
                <>
                    <DiscussionTitle
                        discussionThread={activeDiscussionThread}/>
                    <hr className='row no-gutters' />
                    <div
                        id="discussionthread-messages-container"
                        className="row wrapper justify-content-start flex-grow-1 no-gutters">
                        <DiscussionThread/>
                    </div>
                    <hr className='row no-gutters' />
                    <div className='row justify-content-end no-gutters'>
                        <WrappedDiscussionThreadReply />
                    </div>
                </>
            )}
            {state === 'loading' && (
                <Spinner/>
            )}
        </>
    );
};

export default DiscussionThreadContainer;