// Types
import { IFolder } from '@appTypes/folder';
// Services
import DiscussionThreadService from './DiscussionThreadService';

export enum FolderSelection{
    All,
    Received,
    Sent,
    Favorite,
    Outbox,
    Draft,
    Archive,
    Trash,
    // TODO remove when all folders are implemented
    Disabled
}

class FolderService {
    getAll(): IFolder[] {
        return (Array<IFolder>(
            { id: 0, name: 'folders.all', icon: 'email', folderSelection: FolderSelection.All, path: '/', discussionThreads: [], hideInFolderList: true },
            { id: 1, name: 'folders.inbox', icon: 'email', folderSelection: FolderSelection.Received, path: 'received', discussionThreads: [], fetchFunction: (requestPayload) => DiscussionThreadService.getReceived(requestPayload) },
            { id: 2, name: 'folders.sent', icon: 'paperplane', path: 'sent', folderSelection: FolderSelection.Sent, discussionThreads: [], fetchFunction: (requestPayload) => DiscussionThreadService.getSent(requestPayload) },
            { id: 3, name: 'folders.favorites', icon: 'favourite', path: 'favourite', folderSelection: FolderSelection.Disabled,  discussionThreads: [] },
            { id: 4, name: 'folders.outbox', icon: 'reminder', path: 'outbox', folderSelection: FolderSelection.Disabled, discussionThreads: [] },
            { id: 5, name: 'folders.drafts', icon: 'text-document', path: 'draft', folderSelection: FolderSelection.Disabled, discussionThreads: [] },
            { id: 6, name: 'folders.archive', icon: 'folder-building', path: 'archive', folderSelection: FolderSelection.Disabled, discussionThreads: [] },
            { id: 7, name: 'folders.trash', icon: 'delete', path: 'trash', folderSelection: FolderSelection.Disabled, discussionThreads: [] }
        ));
    }

    private folderSelectionMap: { [name: string]: FolderSelection } = {
        'all': FolderSelection.All,
        'received': FolderSelection.Received,
        'sent': FolderSelection.Sent,
        'favorite': FolderSelection.Favorite,
        'outbox': FolderSelection.Outbox,
        'draft': FolderSelection.Draft,
        'archive': FolderSelection.Archive,
        'trash': FolderSelection.Trash
    };

    getFolderSelection(name: string): FolderSelection {
        return this.folderSelectionMap[name] || FolderSelection.Disabled;
    }
}

export default new FolderService();
