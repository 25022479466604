// Vendors
import React from 'react';
import classNames from 'classnames';
// Context
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Types
import {
    IRecord,
    IStudentRecords,
    ITeacherRecords,
    IPersonnelRecords,
    ITrainingCoordinatorRecords,
    IWorkplaceInstructorRecords
} from '@appTypes/recipients';
import { LoadingState } from '@appTypes/loadingstates';
// Services
import RecipientService from '@services/RecipientService';
// Other
import { RecipientSelectionTypes } from './RecipientsPersonList';


export interface IPrimusSearchRecord {
    id: string;
    schoolID: string;
    caption: string;
}

interface IPrimusSearchButtonProps {
    record: IPrimusSearchRecord;
    activeButtonId: string;
    recipientType: RecipientSelectionTypes;
}

const PrimusSearchButton = ({ record, activeButtonId, recipientType }: IPrimusSearchButtonProps) => {
    const { dispatch } = MessagingState();

    const setActiveButtonId = (id: string) => {
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: id
        });
    };

    const onClickPrimusSearch = async (event: React.MouseEvent<HTMLButtonElement>, schoolId: string, searchId: string, selectionType: RecipientSelectionTypes, currentSchoolName: string) => {
        event.preventDefault();
        const searchButtonId = `${recipientType}-${schoolId}-${searchId}`;

        if (searchButtonId === activeButtonId) {
            return;
        }

        setActiveButtonId(searchButtonId);

        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: RecipientSelectionTypes.Loading,
                records: []
            }
        });

        try {
            let responseRecords : IRecord[];

            switch (selectionType) {
                case RecipientSelectionTypes.Students:
                    responseRecords = (await RecipientService.getPrimusSearchRecords<IStudentRecords>('students', schoolId, searchId, { studentRecords: [] })).studentRecords;
                    break;
                case RecipientSelectionTypes.Teacher:
                    responseRecords = (await RecipientService.getPrimusSearchRecords<ITeacherRecords>('teachers', schoolId, searchId, { teacherRecords: [] })).teacherRecords;
                    break;
                case RecipientSelectionTypes.Personnel:
                    responseRecords = (await RecipientService.getPrimusSearchRecords<IPersonnelRecords>('personnel', schoolId, searchId, { personnelRecords: [] } )).personnelRecords;
                    break;
                case RecipientSelectionTypes.WorkplaceInstructors:
                    responseRecords = (await RecipientService.getPrimusSearchRecords<IWorkplaceInstructorRecords>('workplaceinstructors', schoolId, searchId, {workplaceInstructorRecords: [] })).workplaceInstructorRecords;
                    break;
                case RecipientSelectionTypes.TrainingCoordinators:
                    responseRecords = (await RecipientService.getPrimusSearchRecords<ITrainingCoordinatorRecords>('trainingcoordinators', schoolId, searchId, { trainingCoordinatorRecords: [] })).trainingCoordinatorRecords;
                    break;
                default:
                    responseRecords = [];
            }

            if (RecipientService.loadingState !== LoadingState.Loading) {
                dispatch({
                    type: ActionType.SET_RECIPIENTLIST_VIEW,
                    payload: {
                        showRecipientList: selectionType,
                        records: responseRecords,
                        currentSchool: currentSchoolName,
                        currentClass: record.caption
                    }
                });
            }
        } catch {
            dispatch({
                type: ActionType.SET_RECIPIENTLIST_VIEW,
                payload: {
                    showRecipientList: RecipientSelectionTypes.Error,
                    records: []
                }
            });
        }
    };

    const isActive = [recipientType, record.schoolID, record.id].join('-') === activeButtonId;

    return (
        <div key={record.id}>
            <button
                className={classNames('recipient-list-group-item-gradebook', { 'active': isActive })}
                onClick={(e) => onClickPrimusSearch(e, record.schoolID, record.id, recipientType, record.caption)}>
                <div className='recipient-list-text-gradebook'>
                    {record.caption}
                </div>
            </button>
        </div>
    );
};

export default PrimusSearchButton;