// Vendors
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Icon } from '@vismaux/react-vud';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
// Context
import { useToast } from '@context/ToastContext';
import { ActionType } from '@context/ActionTypes';
import { MessagingState, initialState } from '@context/Context';
// Types
import { IFolder } from '@appTypes/folder';
import { LoadingState } from '@appTypes/loadingstates';
// Services
import FolderService, { FolderSelection } from '@services/FolderService';
// Hooks
import { useUpdateInboxUnreadCount } from '@hooks/useUpdateInboxUnreadCount';
// Components
import FolderListItem from './FolderListItem';
import WilmaNavLink from './WilmaReactRouter/WilmaNavLink';
// Other
import { getToThreadNavigationPath } from '../utils/utils';
import MessageModal from './Modals/MessageModal';

const FolderList = () => {
    const { t } = useTranslation();
    const { folderParam } = useParams();
    const folders = FolderService.getAll();
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();
    const { createToast } = useToast();
    const { dispatch } = MessagingState();
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        amplitude.track('folder_selected', {
            folder_name: folderParam,
        });

        if (folderParam === 'received') {
            updateInboxUnreadCount();
        }
    }, [folderParam, updateInboxUnreadCount]);

    const [oldMessagesPath, setOldMessagesPath] = useState('');

    useEffect(() => {
        // empty slug is old type user
        const fallbackSlug = '';
        // note data-slug includes / at the beginning
        const slug = window.frameElement ? window.frameElement.getAttribute('data-slug') : fallbackSlug;
        setOldMessagesPath(`${window.origin}${slug}/oldmessages`);
    }, []);

    const handleFolderClick = (selectedFolder: boolean) => {
        if (selectedFolder) {
            return;
        }
        dispatch({
            type: ActionType.SET_SEARCH_PAYLOAD,
            payload: initialState.searchPayload,
        });
        dispatch({
            type: ActionType.SET_SEARCH_FOCUS,
            payload: false,
        });
        dispatch({
            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
            payload: { thread: null, loadingState: LoadingState.Done },
        });
    };

    const folderListItemOnClick = () => {
        createToast({ toastType: 'danger', title: t('errors.notImplemented') });
    };

    const toggleOldMessageModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const oldMessagesFooterContent = (
        <div>
            <button
                type="button"
                className="btn btn-default"
                onClick={toggleOldMessageModal}>
                {t('prompts.confirm.no')}
            </button>
            <button
                type="button"
                className="btn btn-primary bg-secondary action-button float-right"
                onClick={() => {
                    window.top?.open(oldMessagesPath, '_blank');
                    toggleOldMessageModal();
                }}>
                {t('prompts.confirm.yes')}
            </button>
        </div>
    );

    return (
        <nav
            className="list-group full-height folder-list"
            title={t('titles.folderSelection')}>
            <WilmaNavLink
                className="btn btn-primary bg-secondary action-button"
                id="new-message-button"
                to={`${getToThreadNavigationPath(location.pathname, 'compose')}${location.search}`}
                relative="path"
                tabIndex={0}>
                <Icon
                    name="edit"
                    dynamic
                    size="sm"
                />
                <div className="folder-list-action-text">{t('newMessage')}</div>
            </WilmaNavLink>
            {folders
                .filter(folder => !folder.hideInFolderList)
                .map((folder: IFolder) => {
                    //TODO: Remove when all folders are implemented
                    const isDisabled = folder.folderSelection === FolderSelection.Disabled;
                    const selectedFolder = folderParam === folder.path;
                    const folderListItemWrapperClass = classNames('folder-list-group-item', {
                        active: selectedFolder,
                        disabled: isDisabled,
                    });
                    return !isDisabled ? (
                        <WilmaNavLink
                            key={folder.id}
                            className={folderListItemWrapperClass}
                            aria-current={selectedFolder}
                            to={`/${folder.path}/page/1${location.pathname.match(/\/compose(.*)$/) ? '/compose' : ''}`}
                            disableLink={selectedFolder}
                            onClick={() => handleFolderClick(selectedFolder)}
                            relative="path"
                            tabIndex={0}>
                            <FolderListItem
                                folder={folder}
                                showIcons={true}
                                showUnread={folder.folderSelection === FolderSelection.Received}
                            />
                        </WilmaNavLink>
                    ) : (
                        <li
                            className={folderListItemWrapperClass}
                            key={folder.id}
                            onClick={folderListItemOnClick}>
                            <FolderListItem
                                folder={folder}
                                showIcons={true}
                                showUnread={false}
                            />
                        </li>
                    );
                })}
            <button
                className="btn btn-secondary mt-auto mb-64"
                aria-label={t('buttons.oldMessages')}
                onClick={toggleOldMessageModal}>
                {t('buttons.oldMessages')}
            </button>
            <MessageModal
                isShowing={isModalOpen}
                toggle={toggleOldMessageModal}
                modalSize="modal-lg"
                title={''}
                bodyContent={<div className="text-center pre-line-wrap">{t('infoModal.bodyOldMessages')}</div>}
                footerContent={oldMessagesFooterContent}
            />
        </nav>
    );
};

export default FolderList;
