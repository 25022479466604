import React from 'react';
type IconProps = React.SVGProps<SVGSVGElement>;

// Custom icon as svg since the icon is not available in the VUD icons
const ReplyAllIcon: React.FC<IconProps> = props => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Reply All"
        {...props}>
        <g clipPath="url(#clip0_4736_4694)">
            <path
                d="M15.9995 9.77022C15.1416 8.38756 14.352 7.6156 13.36 7.18982C12.3408 6.75293 11.0669 6.66541 9.33797 6.66541V9.33207L5.3501 5.12363L9.33797 0.888062V3.55473C10.2073 3.53666 11.076 3.61115 11.9296 3.77695C12.6558 3.91495 13.3404 4.21913 13.9302 4.66584C15.1177 5.59917 15.7753 7.2232 16.0004 9.7712L15.9995 9.77022ZM8.45222 5.77782C9.70463 5.73848 10.9582 5.7927 12.2026 5.94004C12.9802 6.05672 13.7202 6.35311 14.364 6.80581C13.7636 5.91248 13.2487 5.32272 12.3936 4.95338C11.5566 4.59205 10.4147 4.44449 8.45 4.44449V3.5556L6.77422 5.08272L8.45222 6.66671V5.77782Z"
                fill="currentColor"
            />
        </g>
        <g clipPath="url(#clip1_4736_4694)">
            <path
                d="M10.666 15.1036C9.80815 13.7209 9.01855 12.949 8.02646 12.5232C7.00734 12.0863 5.73344 11.9988 4.00447 11.9988V14.6654L0.0166016 10.457L4.00447 6.22144V8.8881C4.87377 8.87003 5.74252 8.94452 6.59614 9.11032C7.32229 9.24832 8.00692 9.55251 8.59673 9.99921C9.78423 10.9325 10.4418 12.5566 10.6669 15.1046L10.666 15.1036ZM3.11873 11.1112C4.37113 11.0719 5.62471 11.1261 6.86909 11.2734C7.64672 11.3901 8.38668 11.6865 9.03053 12.1392C8.43013 11.2459 7.91524 10.6561 7.06007 10.2868C6.22306 9.92542 5.0812 9.77786 3.11651 9.77786V8.88897L1.44072 10.4161L3.11873 12.0001V11.1112Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_4736_4694">
                <rect
                    width="10.6667"
                    height="10.6667"
                    transform="translate(5.3335)"
                />
            </clipPath>
            <clipPath id="clip1_4736_4694">
                <rect
                    width="10.6667"
                    height="10.6667"
                    transform="translate(0 5.33337)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default ReplyAllIcon;
