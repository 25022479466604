// Vendors
import { CSSObjectWithLabel, StylesConfig } from 'react-select';
// Other
import { StringOption } from './WilmaAsyncSelect';

function menu(provided: CSSObjectWithLabel) {
    return {
        ...provided,
        marginTop: '1px' // Dropdown menu position
    };
}

function option(provided: CSSObjectWithLabel) {
    return {
        ...provided,
        cursor: 'pointer'
    };
}

function indicatorSeparator(provided: CSSObjectWithLabel) {
    return {
        ...provided,
        display: 'none' // Hide the vertical line
    };
}

function multiValue(provided: CSSObjectWithLabel) {
    return {
        ...provided,
        backgroundColor: 'transparent',
        border: '1px solid #0071bd',
        borderRadius: '4px',
        '>div': {
            fontSize: '1.4rem', // Selection font-size
            '>div': {
                color: '#0071bd' // Name coloring
            }
        }
    };
}

// Input field container
function input(provided: CSSObjectWithLabel) {
    return {
        ...provided,
        margin: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
        heigh: '32px',
        '>input[id*=react-select-]': {
            boxShadow: 'none'
        }
    };
}

function valueContainer(provided: CSSObjectWithLabel) {
    return {
        ...provided,
        pointer: 'cursor',
        padding: '0px 8px'
    };
}

function indicatorsContainer(provided: CSSObjectWithLabel) {
    return {
        ...provided,
        paddingTop: '0px' // Keep the remove selection & caret in the middle
    };
}

function dropdownIndicator(provided: CSSObjectWithLabel) {
    return {
        ...provided,
        paddingTop: '5px' // Align the caret down with the remove selection button center
    };
}

export function getStyles(isInvalid: boolean): StylesConfig<StringOption, true> {
    const commonStyles: StylesConfig<StringOption, true> = {
        menu: menu,
        option: option,
        control: (provided: CSSObjectWithLabel, { isFocused }) => ({
            ...provided,
            maxHeight: '25rem',
            width: '100%',
            overflowY: 'auto',
            cursor: 'pointer',
            borderRadius: '0px',
            minHeight: '32px',
            borderColor: isInvalid ? 'var(--input-error-text-color)' : '#c1c3c5',
            boxShadow: isFocused && isInvalid ? 'red' : 'none',
            '&:hover': {
                borderColor: isInvalid ? 'red' : '#c1c3c5'
            }
        }),
        indicatorSeparator: indicatorSeparator,
        multiValue: multiValue,
        input: input,
        valueContainer: valueContainer,
        indicatorsContainer: indicatorsContainer,
        dropdownIndicator: dropdownIndicator,
        container: (provided: CSSObjectWithLabel) => ({
            ...provided,
            flex: 1,
            marginBottom: isInvalid ? '10px' : '-10px' // Set the container height to keep the error message below the component
        })
    };

    return { ...commonStyles };
}
