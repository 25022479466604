import React from 'react';
type IconProps = React.SVGProps<SVGSVGElement>;

// Custom icon as svg since the icon is not available in the VUD icons
const ReplyIcon: React.FC<IconProps> = props => (
    <svg
        width="16"
        height="16"
        viewBox="20 12 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Reply"
        {...props}>
        <path
            d="M26.29 13C27 12.69 26.93 16 27.11 16C31.17 16 34.45 18 35 22.42C35.06 22.98 34.7 23.03 34.34 22.61C32.67 20.61 29.74 20.13 27.34 20.41C27.15 20.41 27.2 23.05 26.34 23.05C25.48 23.05 21.09 19.44 21 18.22C20.92 17.23 23.9 14.21 26.29 13Z"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
        />
    </svg>
);

export default ReplyIcon;
