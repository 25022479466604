// Vendors
import React from 'react';
import { Icon } from '@vismaux/react-vud';
import { useTranslation } from 'react-i18next';
// Context
import { MessagingState } from '@context/Context';
// Types
import { LoadingState } from '@appTypes/loadingstates';
// Components
import GradebookContainer from './GradebookContainer';
import IndexRecordsCategory from './IndexRecordsCategory';
import StudentRecordsCategory from './StudentRecordsCategory';
import GuardianRecordsCategory from './GuardianRecordsCategory';

const RecipientCategories = () => {
    const { state: { selectedSchoolId, currentUser, generalRecipientData } } = MessagingState();
    const { t } = useTranslation();

    return (
        <>
            {selectedSchoolId === '0' && (
                <div className="list-group-recipients recipients-list">
                    {currentUser?.roleType === 'Teacher' && (
                        <GradebookContainer />
                    )}
                    {(currentUser?.roleType === 'Student') && (
                        <StudentRecordsCategory />
                    )}
                    {(currentUser?.roleType === 'Guardian' || currentUser?.roleType === 'OldStyleGuardian') && (
                        <GuardianRecordsCategory />
                    )}
                    {generalRecipientData.loadingState === LoadingState.Error && (
                        <div
                            className='error'>
                            <Icon
                                name='error'
                                size="sm"/>
                            <span className='error-text'>{t('errors.generalRecipientDataFetchFailed')}</span>
                        </div>
                    )}
                </div>
            )}
            {selectedSchoolId !== '0' && (
                <div className="list-group-recipients recipients-list">
                    <IndexRecordsCategory />
                </div>
            )}
        </>
    );
};

export default RecipientCategories;