// Vendors
//import classNames from 'classnames';
import { Guid } from 'typescript-guid';
import { Icon } from '@vismaux/react-vud';
import { useTranslation } from 'react-i18next';
import React, { /*useEffect,*/ useState } from 'react';
// Context
import { MessagingState } from '@context/Context';
// Hooks
import useModal from '@hooks/useModal';
import { useCancelThreadHandler } from '@hooks/useCancelThreadHandler';
// Components
import CancelModal from '@components/Modals/CancelModal';

const MessageSent = () => {
    const { t } = useTranslation();
    const {
        state: { lastSentMessageData, paginationData, discussionThreads },
    } = MessagingState();
    const modal = useModal();
    const { cancelThreadHandler } = useCancelThreadHandler();

    const defaultAmplitudeProps = {
        guid_id: Guid.create().toString(),
    };
    const [amplitudeProps] = useState(defaultAmplitudeProps);

    // TODO: This will be implemented later with new UI design
    //const [allowCancelButton, setAllowCancelButton] = useState(lastSentMessageData.canCancelThread);
    /*useEffect(() => {
        const timer = setTimeout(() => {
            setAllowCancelButton(false);
        }, 5000);

        return () => clearTimeout(timer); // This will clear the timer if the component is unmounted before the timer ends
    }, []);*/

    const handleCancelModalConfirm = () => {
        lastSentMessageData.threadId &&
            cancelThreadHandler(lastSentMessageData.threadId, discussionThreads, paginationData, amplitudeProps);
    };

    return (
        <>
            <div className="sent-message-container">
                <Icon
                    name="paperplane"
                    dynamic
                    size="lg"
                    className="paperplane-icon"
                />
                <p className="sent-message-text">{t('messageSent')}</p>
                {/*TODO: This will be implemented later with new UI design
                <button
                    className={classNames(
                        'btn btn-primary bg-secondary action-button',
                        !allowCancelButton ? 'disabled' : ''
                    )}
                    onClick={() => modal.toggle()}
                    disabled={!allowCancelButton}>
                    {t('buttons.undo')}
                </button>*/}
            </div>
            <CancelModal
                isShowing={modal.isOpen}
                toggle={modal.toggle}
                title={t('prompts.confirm.undoMessage')}
                canCancel={lastSentMessageData.canCancelThread}
                bodyContent={<div />}
                handleConfirm={handleCancelModalConfirm}
            />
        </>
    );
};

export default MessageSent;
