// Vendors
import FocusTrap from 'focus-trap-react';
import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Modal, ModalSize, ModalType } from '@vismaux/react-nc4';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Components
import { RecipientSelectionTypes } from './RecipientsPersonList';
// Other
import { getLabel } from '@common-utils';
import { StringOption } from '@components/WilmaAsyncSelect/WilmaAsyncSelect';

interface ModalProps {
    isShowing: boolean,
    toggle: () => void,
    title?: ReactNode,
    bodyContent: ReactNode,
    footerContent?: ReactNode,
    modalType?: ModalType,
    modalSize?: ModalSize
    handleClose?: () => void
    handleConfirm?: () => void
}
let modalElement = document.getElementById('modal-root') as HTMLElement;
//needed to create dom element for test
if (!modalElement) {
    modalElement = document.createElement('div');
    modalElement.setAttribute('id', 'modal-root');
    document.body.appendChild(modalElement);
}

const SelectRecipientsModal = (props: ModalProps) => {
    const { state: { message, modalRecipients }, dispatch } = MessagingState();

    const { t } = useTranslation();

    const clearStates = () => {
        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: RecipientSelectionTypes.None,
                records: []
            }
        });
        dispatch({
            type: ActionType.SET_PRIMUS_SEARCHES,
            payload: {
                studentRecords: [],
                teacherRecords: [],
                personnelRecords: [],
                workplaceInstructorRecords: [],
                trainingCoordinatorRecords: []
            }
        });
        dispatch({
            type: ActionType.SET_SELECTED_SCHOOL,
            payload: '0'
        });
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: ''
        });
    };

    const handleCancel = () => {
        clearStates();
        props.toggle();
    };

    const handleConfirm = () => {
        const recipientsUsers = modalRecipients.modalRecipientOptions.flatMap(option => {
            const group = option.value;
            return option.options.map(o => ({ label: o.label, roleGuid: o.value, roleType: o.roleType, groupLabel: group }));
        });

        dispatch({
            type: ActionType.SET_MESSAGE, payload: {
                title: message.title,
                recipients: recipientsUsers,
                message: message.message,
                seeNames: message.seeNames,
                seeResponses: message.seeResponses
            }
        });

        const singleUserStringOptions : StringOption[] = modalRecipients.modalRecipientOptions.filter(o => o.isGroup === false).flatMap(option => {
            return option.options.map(option => ({ label: option.label, value: option.value, roleGuid: option.value, roleType: option.roleType, groupLabel: option.label, allowMessaging: option.allowMessaging, name: option.name, nameAbbreviation: option.nameAbbreviation, schoolNames: option.schoolNames, extraInfo: option.extraInfo, isGroup: false}));
        });

        const groupStringOptions = modalRecipients.modalRecipientOptions
            .filter(o => o.isGroup === true)
            .map(option => {
                const groupOption : StringOption = {
                    label: getLabel(option),
                    value: option.value,
                    name: '',
                    allowMessaging: true,
                    roleType: '',
                    schoolNames: [],
                    extraInfo: '',
                    isGroup: true
                };
                return groupOption;
            });

        dispatch({
            type: ActionType.SET_SELECTED_RECIPIENTS, payload: { options: [...singleUserStringOptions, ...groupStringOptions] }
        });
        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: RecipientSelectionTypes.None,
                records: []
            }
        });
        dispatch({
            type: ActionType.SET_SELECTED_SCHOOL,
            payload: '0'
        });
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: ''
        });
        props.toggle();
    };

    return props.isShowing ? createPortal(
        <div>
            <FocusTrap
                focusTrapOptions={ {
                    fallbackFocus: '#dialog'
                } }>
                <div
                    id="dialog"
                    tabIndex={-1}>
                    <Modal
                        bodyContent={props.bodyContent}
                        title={props.title}
                        modalSize={props.modalSize ? props.modalSize : 'modal-xl'}
                        onModalClose={handleCancel}
                        modalType={props.modalType}
                        footerContent={props.footerContent ? props.footerContent : (
                            <div>
                                <button
                                    type="button"
                                    autoFocus
                                    className="btn btn-primary bg-secondary action-button"
                                    onClick={() => handleConfirm()}>{t('selectRecipients.confirmSelectRecipients')}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={handleCancel}>{t('prompts.confirm.cancel')}
                                </button>
                            </div>
                        )} />
                </div>
            </FocusTrap>
        </div>,
        modalElement
    ) : null;
};

export default SelectRecipientsModal;
