// Vendors
import { Spinner } from '@vismaux/react-vud';
import React, { createContext, useContext } from 'react';
// Types
import { IFeatureFlags } from '@appTypes/featureflag';
// Services
import { fetchFeatureFlags } from '@services/FeatureFlagService';

interface IFeatureFlagContextProps {
    children: React.ReactNode,
    preloadedFeatureFlags?: Partial<IFeatureFlags>
    preloadedLoadingState?: boolean
}

const defaultFeatureFlags: IFeatureFlags = {
    collectAmplitudeData: false
};

const FeatureFlagContext = createContext<IFeatureFlags>(defaultFeatureFlags);

export const useFeatureFlags = () => useContext(FeatureFlagContext);

export const FeatureFlagProvider = ({ children, preloadedFeatureFlags, preloadedLoadingState }: IFeatureFlagContextProps) => {
    // Support skipping feature flag setup from backend by using preloaded data
    const initialFeatureFlags = {...defaultFeatureFlags, ...preloadedFeatureFlags };
    const initialLoadingState = preloadedLoadingState ?? true;
    const [isLoading, setIsLoading] = React.useState<boolean>(initialLoadingState);
    const [featureFlags, setFeatureFlags] = React.useState(initialFeatureFlags);

    React.useEffect(() => {
        (async () => {
            try {
                const featureFlagsData = await fetchFeatureFlags();
                setFeatureFlags(featureFlagsData);
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <FeatureFlagContext.Provider value={featureFlags}>
            {isLoading ? (
                <div id='loading-container'>
                    <Spinner></Spinner>
                </div>
            ) :
                children}
        </FeatureFlagContext.Provider>
    );
};