// Vendors
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListGroup } from '@vismaux/react-vud';
// Context
import { MessagingState } from '@context/Context';
// Types
import { IPrimusSearchRecords, IPrimusSearches } from '@appTypes/recipients';
// Components
import PrimusSearchButton from './PrimusSearchButton';
// Other
import { RecipientSelectionTypes } from './RecipientsPersonList';

export type PrimusSearchRoleType = 'student' | 'teacher' | 'personnel' | 'workplaceInstructor' | 'trainingCoordinator';

const PrimusSearches = () => {
    const { state: { activeButtonId, primusSearches } } = MessagingState();
    const { t } = useTranslation();

    const primusSearchHasValues = (primusSearches: IPrimusSearches) : boolean => {
        return Object.values(primusSearches).some(records => records.length > 0);
    };

    return (
        <>
            {primusSearchHasValues(primusSearches) && (
                <ListGroup title={t('selectRecipients.primusSearch')}>
                    {primusSearches?.studentRecords?.map((record: IPrimusSearchRecords) => {
                        return (
                            <PrimusSearchButton
                                key={record.id} 
                                record={record} 
                                activeButtonId={activeButtonId} 
                                recipientType={RecipientSelectionTypes.Students} 
                            />
                        );
                    })}
                    {primusSearches?.teacherRecords.map((record: IPrimusSearchRecords) => {
                        return (
                            <PrimusSearchButton
                                key={record.id} 
                                record={record} 
                                activeButtonId={activeButtonId} 
                                recipientType={RecipientSelectionTypes.Teacher} 
                            />
                        );
                    })}
                    {primusSearches?.personnelRecords.map((record: IPrimusSearchRecords) => {
                        return (
                            <PrimusSearchButton
                                key={record.id} 
                                record={record} 
                                activeButtonId={activeButtonId} 
                                recipientType={RecipientSelectionTypes.Personnel} 
                            />
                        );
                    })}
                    {primusSearches?.trainingCoordinatorRecords.map((record: IPrimusSearchRecords) => {
                        return (
                            <PrimusSearchButton
                                key={record.id} 
                                record={record} 
                                activeButtonId={activeButtonId} 
                                recipientType={RecipientSelectionTypes.TrainingCoordinators} 
                            />
                        );
                    })}
                    {primusSearches?.workplaceInstructorRecords.map((record: IPrimusSearchRecords) => {
                        return (
                            <PrimusSearchButton
                                key={record.id} 
                                record={record} 
                                activeButtonId={activeButtonId} 
                                recipientType={RecipientSelectionTypes.WorkplaceInstructors} 
                            />
                        );
                    })}
                </ListGroup>
            )}
        </>
    );
};

export default PrimusSearches;