// Types
import { IFeatureFlags } from '@appTypes/featureflag';
import { AppConfigDataAPI } from '@root/@types/API/featureFlagAPI';
// Other
import { messagingHttpClient } from '@root/http-common';

export const fetchFeatureFlags = async () => {
    const featureFlagData = (await messagingHttpClient.get<AppConfigDataAPI[]>('/ms/messaging/featureflag')).data;

    const result: IFeatureFlags = {
        collectAmplitudeData: featureFlagData.find(flag => flag.configName === 'collectAmplitudeData')?.isEnabled ?? false,
    };
    return result;
};
