// Vendors
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { DraftsOutlined, MarkEmailUnreadOutlined } from '@mui/icons-material';
import classNames from 'classnames';
// Types
import { IDiscussionThreadListItem, IDiscussionThreadListUser } from '@appTypes/discussionthread';
// Hooks
import { useUpdateInboxUnreadCount } from '@hooks/useUpdateInboxUnreadCount';
import { useUpdateMessageReadStatus } from '@hooks/useUpdateMessageReadStatus';
// Other
import { getSentAtMessage, getUserDisplayName, isValidUser } from '@common-utils';
import { FolderSelection } from '@root/services/FolderService';

interface Props {
    discussionThread: IDiscussionThreadListItem;
    isActive?: boolean;
    isUnread?: boolean;
    foundInFolder?: FolderSelection;
    isFocused?: boolean;
}

export const getNewestMessageSentAt = (latestActivity: Date) => {
    return getSentAtMessage(latestActivity, false);
};

export const getUserDisplayNames = (users?: IDiscussionThreadListUser[]) => {
    if (!users || users.length === 0) return t('errors.unknownUser');

    const filteredUsers = users.filter(isValidUser);
    const displayedUsers = filteredUsers.map(getUserDisplayName);
    const result = displayedUsers.join(', ');

    return result;
};

export const DiscussionThreadListItem = (props: Props) => {
    const { t } = useTranslation();
    const { updateMessageReadStatus } = useUpdateMessageReadStatus();
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();
    const { folderParam } = useParams();
    const [isHovered, setIsHovered] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const discussionThread = props.discussionThread;
    const isActive = props.isActive;
    const isUnread = props.isUnread;
    const foundInFolder = props.foundInFolder;

    const shouldShowUnreadButton = isActive || isHovered || isFocused;

    useEffect(() => {
        if (props.isFocused !== undefined) {
            setIsFocused(props.isFocused);
        }
    }, [props.isFocused]);

    const getDisplayName = () => {
        // If search is done, the shown names are defined by 'foundInFolder'.
        if (foundInFolder) {
            return foundInFolder === FolderSelection.Sent
                ? getUserDisplayNames(discussionThread.listRecipients)
                : getUserDisplayName(discussionThread.author);
        }

        // Otherwise folder defines the names shown.
        if (folderParam === 'sent') {
            return getUserDisplayNames(discussionThread.listRecipients);
        }
        if (folderParam === 'received') {
            return getUserDisplayName(discussionThread.author);
        }
        return getUserDisplayName(discussionThread.author);
    };

    const handleMessageRead = async () => {
        if (isUnread) {
            await updateMessageReadStatus(discussionThread.id, discussionThread.latestMessageId);
        } else {
            await updateMessageReadStatus(discussionThread.id);
        }
        updateInboxUnreadCount();
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = (event: React.FocusEvent) => {
        // Check if the focus is moving to an element (unread button) inside the list item.
        if (event.relatedTarget && event.currentTarget.contains(event.relatedTarget as Node)) {
            return;
        }
        setIsFocused(false);
    };

    return (
        <>
            {discussionThread ? (
                <div
                    className="row discussionThreadListItem"
                    aria-label={t('labels.discussionThreadItem')}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className={classNames('truncate', shouldShowUnreadButton ? 'col-10' : 'col-12')}>
                                {getDisplayName()}
                            </div>
                            <div className="row col-auto">
                                <button
                                    type="button"
                                    className={classNames('btn btn-icon btn-unread', {
                                        'btn-unread--visible': shouldShowUnreadButton,
                                        'btn-unread--hidden': !shouldShowUnreadButton,
                                    })}
                                    aria-label={isUnread ? t('buttons.markAsRead') : t('buttons.markAsUnread')}
                                    onClick={event => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        handleMessageRead();
                                    }}
                                    tabIndex={0}>
                                    {isUnread ? (
                                        <DraftsOutlined
                                            fontSize="large"
                                            // eslint-disable-next-line react/jsx-curly-spacing
                                            sx={{ padding: 0, margin: 0 }}
                                        />
                                    ) : (
                                        <MarkEmailUnreadOutlined
                                            fontSize="large"
                                            // eslint-disable-next-line react/jsx-curly-spacing
                                            sx={{ padding: 0, margin: 0 }}
                                        />
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 truncate">{discussionThread.name}</div>
                            <div className="col-4 date">{getNewestMessageSentAt(discussionThread.lastActivity)}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>{t('discussionThreadMissing')}</div>
            )}
        </>
    );
};
