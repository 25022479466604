// Vendors
import { IUser, IUserWilma} from '@appTypes/user';
// Other
import { messagingHttpClient } from '@root/http-common';

export const fetchUserInfo = async () => {
    const target = '/ms/messaging/UserInfo';
    const response = (await messagingHttpClient.get<IUserWilma>(target)).data;
    const user: IUser =
        {
            roleGuid: response.id,
            label: response.label,
            roleType: response.roleType
        };
    return user;
};

export const expireSession = async () => {
    const target = '/ms/messaging/UserInfo/logout';
    await messagingHttpClient.post(target);
};

export const refreshSession = async () => {
    // Refresh Wilma backend session
    await messagingHttpClient.get('/status');
};
