// Vendors
import { useTranslation } from 'react-i18next';
import { Icon, Spinner } from '@vismaux/react-vud';
import React, { useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
// Context
import { useToast } from '@context/ToastContext';
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Types
import { LoadingState } from '@appTypes/loadingstates';
import { IDiscussionThreadWithMessageIds } from '@appTypes/discussionthread';
// Services
import DiscussionThreadService from '@services/DiscussionThreadService';
// Hooks
import { WithReplyHandlingProps } from '@hoc/withReplyHandling';
// Custom icons
import ReplyAllIcon from '../components/icons/ReplyAllIcon';
import ReplyIcon from './icons/ReplyIcon';
// Other
import { getUserDisplayName, threadHasMessagesFromMultipleAuthors } from '../utils/utils';
// Payloads
import { IDiscussionThreadReplyPayload } from '@root/services/payloads/servicePayloads';

const DiscussionThreadReply = ({ setIsReplyBoxVisible, setIsReplyToAuthor, replyTo }: WithReplyHandlingProps) => {
    const { t } = useTranslation();
    const [isReplyDisabled, setIsReplyDisabled] = useState(false);
    const [reply, setReply] = useState('');
    const toast = useToast();
    const {
        state: { activeThreadMessages, activeDiscussionThread, isReplyBoxVisible, isReplyToAuthor, replyInfo, currentUser },
        dispatch,
    } = MessagingState();
    // Author is part of recipients, so therefore minimum recipient count currently is 2
    const hasSingleRecipient = activeDiscussionThread?.recipients && activeDiscussionThread.recipients.length <= 2;
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.Done);
    const navigate = useNavigate();
    const ref = useRef<NavigateFunction>();
    ref.current = navigate;

    useEffect(() => {
        dispatch({
            type: ActionType.SET_REPLY_BOX_VISIBILITY,
            payload: false,
        });
        dispatch({
            type: ActionType.SET_REPLY_TO_AUTHOR,
            payload: false,
        });
    }, [activeDiscussionThread, dispatch]);

    const sendReply = async () => {
        const discussionThreadNotActive = !activeDiscussionThread || !activeDiscussionThread.id;
        if (isReplyDisabled || discussionThreadNotActive) return;
        setIsReplyDisabled(true);
        try {
            setLoadingState(LoadingState.Loading);
            if (!isReplyToAuthor) {
                const newThread = await DiscussionThreadService.reply(reply, activeDiscussionThread.id);
                await setActiveDiscussionThread(newThread, true);
                setLoadingState(LoadingState.Done);
            } else {
                if (replyInfo.messageId == null) {
                    throw new Error('Should set message id to which replying');
                }
                const discussionReplyThread: IDiscussionThreadReplyPayload = {
                    replyMessage: reply,
                    originalThreadId: activeDiscussionThread.id,
                    messageIdToReplyTo: replyInfo.messageId,
                };
                const newThread = await DiscussionThreadService.createReplyToSender(discussionReplyThread);
                await moveToRefreshedSentFolder(newThread.data.id);
                await setActiveDiscussionThread(newThread.data);
                setLoadingState(LoadingState.Done);
            }
        } catch (error) {
            toast.createToast({
                title: t('errors.messageSendingError'),
                toastType: 'danger',
            });
            setLoadingState(LoadingState.Error);
        }
        setIsReplyDisabled(false);
    };

    const cancelHandler = () => {
        setIsReplyBoxVisible(false);
        setIsReplyToAuthor(false);
        setReply('');
        dispatch({
            type: ActionType.SET_REPLY_ID_AND_USER,
            payload: { messageId: 0, userToReplyTo: null },
        });
    };

    const moveToRefreshedSentFolder = async (threadId: number) => {
        // https://github.com/remix-run/react-router/issues/11240
        // Need to use ref, since async call will not cause re-render for navigation
        ref.current && ref.current(`/sent/page/1/${threadId}`);
        navigate({
            pathname: `/sent/page/1/${threadId}`,
        });
    };

    const setActiveDiscussionThread = async (discussionThread: IDiscussionThreadWithMessageIds, reply?: boolean) => {
        let messageResponse = null;
        if (reply && activeThreadMessages) {
            // Fetch all messages missing between the last fetched message and the reply message
            const latestFetchedMessage = activeThreadMessages.slice(-1)[0];
            const latestFetchedMessageIndex = discussionThread.messages.findIndex(m => m.id === latestFetchedMessage.id);
            const firstMessageIndexToFetch = latestFetchedMessageIndex + 1;
            const newMessageIds = discussionThread.messages
                .slice(firstMessageIndexToFetch)
                .map(messageWithId => messageWithId.id);
            messageResponse = await DiscussionThreadService.postThreadMessages(
                discussionThread.id,
                { messageIds: newMessageIds },
                undefined
            );
        }
        dispatch({
            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
            payload: {
                thread: { ...discussionThread, lastReadMessageId: discussionThread.messages.slice(-1)[0].id },
                activeThreadMessages: messageResponse ? messageResponse.messages : activeThreadMessages,
                loadingState: LoadingState.Done,
            },
        });
        dispatch({
            type: ActionType.SCROLL_MESSAGE_LIST_TO_BOTTOM,
            payload: true,
        });
        setIsReplyBoxVisible(false);
        setReply('');
    };

    const replyToSender = () => {
        if (activeDiscussionThread && activeDiscussionThread.author) {
            const messageIds = activeDiscussionThread.messages.map(message => message.id);
            replyTo(messageIds[messageIds.length - 1], activeDiscussionThread.author);
        }
    };

    const replyToAll = () => {
        setIsReplyBoxVisible(!isReplyBoxVisible);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const reply = e.target.value;
        setReply(reply);
    };

    const showReplyToSenderButton = () => {
        // if recipients are hidden, recipient can always reply only to sender
        if (activeDiscussionThread?.hiddenRecipientsCount ?? 0 > 1) return true;
        const isCurrentUserThreadAuthor = activeDiscussionThread?.author?.roleGuid === currentUser?.roleGuid;
        const threadHasMultipleRecipients = (activeDiscussionThread?.recipients.length ?? 0) > 2;
        return (
            !isCurrentUserThreadAuthor &&
            !threadHasMessagesFromMultipleAuthors(activeThreadMessages, activeDiscussionThread?.author?.roleGuid) &&
            threadHasMultipleRecipients
        );
    };

    return (
        <>
            {isReplyBoxVisible ? (
                <>
                    {isReplyToAuthor && (
                        <div className="col-md-12">
                            <span id="discussion-recipient-text-row">
                                {t('to') + getUserDisplayName(replyInfo.userToReplyTo ?? activeDiscussionThread?.author)}
                            </span>
                        </div>
                    )}
                    <div className="col-md-12 h-screen-20">
                        <textarea
                            autoFocus
                            className="h-100 resize-none"
                            name="message"
                            onChange={onChange}
                            placeholder={t('placeholders.message')}
                        />
                    </div>
                    <div className="row float-right no-gutters">
                        <button
                            type="button"
                            disabled={loadingState === LoadingState.Loading}
                            onClick={() => cancelHandler()}
                            className="btn bg-transparent action-button">
                            <span className="close" />
                            {t('cancel')}
                        </button>
                        <button
                            type="button"
                            onClick={sendReply}
                            disabled={reply.length === 0 || isReplyDisabled || loadingState === LoadingState.Loading}
                            className="btn btn-primary bg-secondary action-button">
                            <Icon
                                name="paperplane"
                                size="sm"
                            />
                            {t('submitMessage')}
                            {loadingState === LoadingState.Loading && <Spinner size="sm" />}
                        </button>
                    </div>
                </>
            ) : (
                <div className="row float-right no-gutters">
                    {
                        // TODO: implemented later as part of discarding messages
                        /*<button
                        type="button"
                        className='btn bg-transparent action-button'>
                        <Icon
                            name="delete"
                            dynamic
                            size="sm" />
                        {t('moveToTrash')}
                    </button>*/
                    }
                    {/* ReplyToSender button is visible in the bottom of container, if there is only 1 message in thread or all messages are from original author */}
                    {showReplyToSenderButton() && (
                        <button
                            type="button"
                            onClick={replyToSender}
                            className={classNames('btn action-button', {
                                'bg-transparent': activeDiscussionThread?.canReplyToAll,
                                'btn-primary bg-secondary': !activeDiscussionThread?.canReplyToAll,
                            })}>
                            <ReplyIcon
                                color={activeDiscussionThread?.canReplyToAll ? 'black' : 'white'}
                                className="icon-spacing"
                            />
                            {t('replyToSender')}
                        </button>
                    )}
                    {activeDiscussionThread?.canReplyToAll && (
                        <button
                            type="button"
                            onClick={replyToAll}
                            className="btn btn-primary bg-secondary action-button">
                            {hasSingleRecipient ? (
                                <ReplyIcon
                                    color="white"
                                    className="icon-spacing"
                                />
                            ) : (
                                <ReplyAllIcon
                                    color="white"
                                    className="icon-spacing"
                                />
                            )}
                            {hasSingleRecipient ? t('reply') : t('replyToAll')}
                        </button>
                    )}
                </div>
            )}
        </>
    );
};

export default DiscussionThreadReply;
