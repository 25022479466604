// Vendors
import React from 'react';
import { ListContainer, Spinner } from '@vismaux/react-vud';
// Context
import { MessagingState } from '@context/Context';
// Types
import { LoadingState } from '@appTypes/loadingstates';
// Services
import RecipientService from '@services/RecipientService';
// Components
import MyTeachersCategoryButton from './MyTeachersCategoryButton';
import MyGuardiansCategoryButton from './MyGuardiansCategoryButton';
// Other
import { RecipientSelectionTypes } from './RecipientsPersonList';

const StudentRecordsCategory = () => {
    const { state: { generalRecipientData } } = MessagingState();

    const guardianRecords = generalRecipientData?.otherGuardians;
    return (
        <>
            {guardianRecords && guardianRecords.length > 0 && (
                <ListContainer>
                    <MyGuardiansCategoryButton
                        guardianRecords={guardianRecords}
                        recipientType={RecipientSelectionTypes.OwnGuardians} />
                </ListContainer>
            )}
            {generalRecipientData.loadingState === LoadingState.Loading && (
                <Spinner/>
            )}
            <ListContainer>
                <MyTeachersCategoryButton
                    recipientType={RecipientSelectionTypes.OwnTeachers}
                    serviceMethod={RecipientService.getOwnTeachers.bind(RecipientService)}/>
            </ListContainer>
        </>
    );
};

export default StudentRecordsCategory;