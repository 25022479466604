// Vendors
import { AxiosResponse, CanceledError } from 'axios';
import { CacheAxiosResponse, CacheRequestConfig } from 'axios-cache-interceptor';
import { messagingHttpClient, messagingHttpClientCached } from '@root/http-common';
// Types
import { ThreadStatus } from '@appTypes/threadStatus';
import { ISearchPayload, ISearchResult } from '@appTypes/search';
import { IMessageWithContent } from '@appTypes/message';
import { IDiscussionThreadWithMessageIds } from '@root/@types/discussionthread';
import { FolderSelectionEnumAPI } from '@root/@types/API/Shared/folderSelectionTypes';
import { SearchResultAPI } from '@root/@types/API/searchAPI';
import { PaginatedMessageResponseAPI, PaginatedResponseAPI } from '@root/@types/API/paginationAPI';
import {
    DiscussionThreadListAPI,
    DiscussionThreadSentListAPI,
    MessageAPI,
    PagedParametersAPI,
    SimpleDiscussionThreadAPI,
    UnreadCountAPI,
} from '@root/@types/API/discussionThreadAPI';
// Services
import { FolderSelection } from './FolderService';
// Other
import { initPaginationData } from '@common-utils';
// payloadTypes
import {
    IDiscussionThreadCreatePayload,
    IDiscussionThreadReplyPayload,
    IDiscussionThreadUpdatePayload,
} from './payloads/servicePayloads';

class DiscussionThreadService {
    private lastRequestTimesByFolder: { [key in FolderSelection]?: string } = {};
    private abortController = new AbortController();
    private discussionThreadAbortController = new AbortController();
    private dtApiEndpoint: string = '/ms/messaging/discussionthread';

    public createPagedUrl = (urlPostfix: string, payload?: PagedParametersAPI) => {
        const url = new URL(`${this.dtApiEndpoint}/${urlPostfix}`, window.location.origin);
        if (payload) {
            url.searchParams.append('pageNumber', payload.pageNumber.toString());
            url.searchParams.append('pageSize', payload.pageSize.toString());
        }
        return url;
    };

    private handleCachedThreadResponse(
        cachedResponse: CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadSentListAPI>>,
        url: URL,
        folder: FolderSelection.Sent
    ): Promise<CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadSentListAPI>>>;

    private handleCachedThreadResponse(
        cachedResponse: CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadListAPI>>,
        url: URL,
        folder: FolderSelection.Received
    ): Promise<CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadListAPI>>>;

    private async handleCachedThreadResponse(
        cachedResponse: CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadListAPI | DiscussionThreadSentListAPI>>,
        url: URL,
        folder: FolderSelection
    ): Promise<CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadListAPI | DiscussionThreadSentListAPI>>> {
        // If response is cached then make a request with timestamp and add the data to the cache
        if (cachedResponse.cached) {
            const cachedDiscussionThreads = cachedResponse.data.discussionThreads;
            // Add lastActivitySince to the query string
            if (this.lastRequestTimesByFolder[folder]) {
                url.searchParams.append('fromTimeStamp', this.lastRequestTimesByFolder[folder]);
            }

            const response = await messagingHttpClient.get<
                PaginatedResponseAPI<DiscussionThreadListAPI | DiscussionThreadSentListAPI>
            >(url.toString(), {
                signal: this.abortController.signal,
            });
            const { requestTime, discussionThreads, cancelledThreadIds } = response.data;

            // Filter out any threads that are already in the cache
            let existingThreads = cachedDiscussionThreads.filter(
                cachedThread => !discussionThreads.some(newThread => newThread.id === cachedThread.id)
            );

            if (cancelledThreadIds?.length) {
                // Further filter out threads that are no more active
                existingThreads = existingThreads.filter(
                    cachedThread => !(cancelledThreadIds as number[]).some(id => cachedThread.id === id)
                );
            }
            cachedResponse.data.discussionThreads = discussionThreads.concat(existingThreads);
            // Update lastRequestTime if new threads are found
            if (discussionThreads.length > 0 && requestTime) {
                this.lastRequestTimesByFolder[folder] = new Date(requestTime).toUTCString();
            }
        }
        return cachedResponse;
    }

    getReceived = async (payload?: PagedParametersAPI): Promise<PaginatedResponseAPI<DiscussionThreadListAPI>> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        const url = this.createPagedUrl('received', payload);
        try {
            let response:
                | CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadListAPI>>
                | AxiosResponse<PaginatedResponseAPI<DiscussionThreadListAPI>>;
            let updatedData:
                | CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadListAPI>>
                | AxiosResponse<PaginatedResponseAPI<DiscussionThreadListAPI>>;
            // If the first page is requested, use the cached response
            if (payload?.pageNumber == 1) {
                const folderId = FolderSelection.Received;
                response = await messagingHttpClientCached.get<PaginatedResponseAPI<DiscussionThreadListAPI>>(
                    url.toString(),
                    {
                        signal: this.abortController.signal,
                        id: folderId.toString(),
                    }
                );
                updatedData = await this.handleCachedThreadResponse(
                    response as CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadListAPI>>,
                    url,
                    folderId
                );
            } else {
                response = await messagingHttpClient.get<PaginatedResponseAPI<DiscussionThreadListAPI>>(url.toString(), {
                    signal: this.abortController.signal,
                });
                updatedData = response;
            }

            // Update received folder lastRequestTime after the first call
            if (
                !this.lastRequestTimesByFolder[FolderSelection.Received] &&
                response.data.requestTime &&
                payload?.pageNumber == 1
            ) {
                this.lastRequestTimesByFolder[FolderSelection.Received] = new Date(response.data.requestTime).toUTCString();
            }

            return {
                discussionThreads: updatedData.data.discussionThreads,
                paginationData: updatedData.data.paginationData,
            };
        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        totalCount: 0,
                        pageSize: 0,
                        currentPage: 0,
                        totalPages: 0,
                    },
                };
            } else {
                throw e;
            }
        }
    };

    InboxUnreadCount = async () => {
        const response = await messagingHttpClient.get<UnreadCountAPI>(`${this.dtApiEndpoint}/unread`);
        return response.data;
    };

    create(data: IDiscussionThreadCreatePayload) {
        return messagingHttpClient.post<IDiscussionThreadWithMessageIds>(this.dtApiEndpoint, data);
    }

    createReplyToSender(data: IDiscussionThreadReplyPayload) {
        return messagingHttpClient.post<IDiscussionThreadWithMessageIds>(`${this.dtApiEndpoint}/replytosender`, data);
    }

    async reply(data: string, id: number) {
        const response = await messagingHttpClient.post<IDiscussionThreadWithMessageIds>(`${this.dtApiEndpoint}/${id}`, {
            reply: data,
        });
        this.updateCaches((discussionThreads: IDiscussionThreadWithMessageIds[]) => {
            discussionThreads.forEach(thread => {
                if (thread.id === id) {
                    thread = response.data;
                }
            });
            return discussionThreads;
        });
        return response.data;
    }

    getSent = async (payload?: PagedParametersAPI): Promise<PaginatedResponseAPI<DiscussionThreadSentListAPI>> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        const url = this.createPagedUrl('sent', payload);
        try {
            let response:
                | CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadSentListAPI>>
                | AxiosResponse<PaginatedResponseAPI<DiscussionThreadSentListAPI>>;
            let updatedData:
                | CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadSentListAPI>>
                | AxiosResponse<PaginatedResponseAPI<DiscussionThreadSentListAPI>>;
            // If the first page is requested, use the cached response
            const urlStr = url.toString();
            const requestParams = {
                id: '',
                signal: this.abortController.signal,
            };
            if (payload?.pageNumber == 1) {
                requestParams.id = FolderSelection.Sent.toString();
                response = await messagingHttpClientCached.get<PaginatedResponseAPI<DiscussionThreadSentListAPI>>(
                    urlStr,
                    requestParams
                );
                updatedData = await this.handleCachedThreadResponse(
                    response as CacheAxiosResponse<PaginatedResponseAPI<DiscussionThreadSentListAPI>>,
                    url,
                    FolderSelection.Sent
                );
            } else {
                response = await messagingHttpClient.get<PaginatedResponseAPI<DiscussionThreadSentListAPI>>(
                    urlStr,
                    requestParams
                );
                updatedData = response;
            }

            // Update sent folder lastRequestTime after the first call
            if (
                !this.lastRequestTimesByFolder[FolderSelection.Sent] &&
                response.data.requestTime &&
                payload?.pageNumber == 1
            ) {
                this.lastRequestTimesByFolder[FolderSelection.Sent] = new Date(response.data.requestTime).toUTCString();
            }

            return {
                discussionThreads: updatedData.data.discussionThreads,
                paginationData: updatedData.data.paginationData,
            };
        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: initPaginationData(),
                };
            } else {
                throw e;
            }
        }
    };

    private mapFolderSelectionEnumAPI = (folder: FolderSelectionEnumAPI): FolderSelection => {
        switch (folder) {
            case FolderSelectionEnumAPI.Received:
                return FolderSelection.Received;
            case FolderSelectionEnumAPI.Sent:
                return FolderSelection.Sent;
            case FolderSelectionEnumAPI.All:
                return FolderSelection.All;
            // Add other cases as needed
            default:
                console.warn(`Unknown folder selection API enum: ${folder}, defaulting to Disabled.`);
                return FolderSelection.Disabled; // Default case
        }
    };

    search = async (searchPayload: ISearchPayload): Promise<PaginatedResponseAPI<ISearchResult>> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        try {
            const response = await messagingHttpClient.post<PaginatedResponseAPI<SearchResultAPI>>(
                '/ms/messaging/Search',
                { ...searchPayload },
                { signal: this.abortController.signal }
            );
            const data = response.data;
            const results = {
                discussionThreads: data.discussionThreads.map(thread => ({
                    ...thread,
                    foundInFolder: this.mapFolderSelectionEnumAPI(thread.foundInFolder),
                })),
                paginationData: data.paginationData,
            };

            return results;
        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: initPaginationData(),
                };
            } else {
                throw e;
            }
        }
    };

    updateRecipientList = async (recipientGuids: string[], threadId: number) => {
        return await this.updateThread({ recipientGUIDs: recipientGuids }, threadId);
    };

    updateThread = async (payload: IDiscussionThreadUpdatePayload, threadId: number) => {
        return messagingHttpClient.patch<IDiscussionThreadWithMessageIds>(`${this.dtApiEndpoint}/${threadId}`, payload);
    };

    cancelThread = async (threadId: number) => {
        return await this.updateThread({ threadStatus: ThreadStatus.cancelled }, threadId);
    };

    postThreadMessages = async (
        threadId: number,
        payload: { messageIds: number[] },
        pagedPayload?: PagedParametersAPI
    ): Promise<PaginatedMessageResponseAPI<IMessageWithContent>> => {
        // Construct the query string for pagination if provided
        const cacheRequestConfig: CacheRequestConfig = { cache: { ttl: 1000 * 60 * 60 * 24, methods: ['get', 'post'] } };
        const queryString = pagedPayload ? `?pageNumber=${pagedPayload.pageNumber}&pageSize=${pagedPayload.pageSize}` : '';
        const cacheId = `${threadId}_Messages`;

        // Retrieve cached messages and their IDs
        const { cachedMessages, cachedMessageIds } = await this.getCachedMessages(cacheId, cacheRequestConfig);

        // If no cached messages are found, fetch and cache new messages
        if (cachedMessages.length === 0) {
            return await this.fetchAndCacheMessages(threadId, payload.messageIds, queryString, cacheId, cacheRequestConfig);
        }

        // Filter out message IDs that are already cached
        const newMessageIds = payload.messageIds.filter(id => !cachedMessageIds.includes(id));
        if (newMessageIds.length === 0) {
            // If all message IDs are already cached, return the cached messages
            return {
                messages: cachedMessages,
                paginationData: { totalCount: cachedMessages.length, pageSize: 0, currentPage: 1, totalPages: 1 },
            };
        }

        // Fetch new messages that are not in the cache
        const newMessages = await this.fetchNewMessages(threadId, newMessageIds, queryString);
        // Combine cached messages with the newly fetched messages
        const combinedMessages = this.combineMessages(cachedMessages, newMessages).sort(
            (a, b) => (a.sentAt?.getTime() ?? 0) - (b.sentAt?.getTime() ?? 0)
        );

        // Update the cache with the combined messages
        await this.updateThreadCache(cacheId, thread => {
            thread.messages = combinedMessages;
            return thread;
        });

        // Return the combined messages with pagination data
        return {
            messages: combinedMessages,
            paginationData: { totalCount: combinedMessages.length, pageSize: 0, currentPage: 1, totalPages: 1 },
        };
    };

    private async getCachedMessages(
        cacheId: string,
        requestConfig: CacheRequestConfig
    ): Promise<{ cachedMessages: IMessageWithContent[]; cachedMessageIds: number[] }> {
        const cachedData = await messagingHttpClientCached.storage.get(cacheId, requestConfig);
        if (cachedData.state !== 'cached') {
            return { cachedMessages: [], cachedMessageIds: [] };
        }

        const cachedResponse = cachedData.data?.data as PaginatedMessageResponseAPI<MessageAPI>;
        if (!cachedResponse) {
            return { cachedMessages: [], cachedMessageIds: [] };
        }

        const cachedMessages: IMessageWithContent[] = cachedResponse.messages.map(message =>
            this.convertApiMessageToStateMessage(message)
        );
        const cachedMessageIds = cachedMessages.map(message => message.id);

        return { cachedMessages, cachedMessageIds };
    }

    private async fetchAndCacheMessages(
        threadId: number,
        messageIds: number[],
        queryString: string,
        cacheId: string,
        requestConfig: CacheRequestConfig
    ): Promise<PaginatedMessageResponseAPI<IMessageWithContent>> {
        const response = await messagingHttpClientCached.post<PaginatedMessageResponseAPI<MessageAPI>>(
            `/ms/messaging/discussionthread/${threadId}/messages${queryString}`,
            { messageIds },
            { ...requestConfig, signal: this.abortController.signal, id: cacheId }
        );

        const messages = response.data?.messages.map(message => this.convertApiMessageToStateMessage(message)) || [];
        return {
            messages,
            paginationData: response.data?.paginationData,
        };
    }

    private async fetchNewMessages(
        threadId: number,
        newMessageIds: number[],
        queryString: string
    ): Promise<IMessageWithContent[]> {
        // Fetch new messages that are not in the cache use non cached request
        const response = await messagingHttpClientCached.post<PaginatedMessageResponseAPI<MessageAPI>>(
            `/ms/messaging/discussionthread/${threadId}/messages${queryString}`,
            { messageIds: newMessageIds },
            // Disable caching for this request
            { cache: false }
        );

        if (response.status !== 200) {
            return [];
        }

        return response.data?.messages.map(message => this.convertApiMessageToStateMessage(message)) || [];
    }

    private combineMessages(
        cachedMessages: IMessageWithContent[],
        newMessages: IMessageWithContent[]
    ): IMessageWithContent[] {
        const combinedMessagesMap = new Map<number, IMessageWithContent>();
        cachedMessages.forEach(message => combinedMessagesMap.set(message.id, message));
        newMessages.forEach(message => combinedMessagesMap.set(message.id, message));

        return Array.from(combinedMessagesMap.values());
    }

    private async updateThreadCache(
        cacheId: string,
        updaterFunction: (thread: IDiscussionThreadWithMessageIds) => IDiscussionThreadWithMessageIds
    ): Promise<void> {
        const cachedData = await messagingHttpClientCached.storage.get(cacheId.toString());
        if (cachedData.state !== 'cached') {
            return;
        }

        const discussionThreadData = cachedData.data?.data as IDiscussionThreadWithMessageIds;
        if (!discussionThreadData) {
            return;
        }

        const updatedThread = updaterFunction(discussionThreadData);
        const updatedCacheResponse = { ...cachedData, data: { ...cachedData.data, data: updatedThread } };
        await messagingHttpClientCached.storage.set(cacheId.toString(), updatedCacheResponse);
    }

    private convertApiMessageToStateMessage(message: MessageAPI): IMessageWithContent {
        return {
            ...message,
            modifiedAt: message.modifiedAt ? new Date(message.modifiedAt) : undefined,
            sentAt: message.sentAt ? new Date(message.sentAt) : undefined,
        };
    }

    getDiscussionThread = async (threadId: number) => {
        this.discussionThreadAbortController.abort();
        this.discussionThreadAbortController = new AbortController();
        try {
            const response = await messagingHttpClient.get<SimpleDiscussionThreadAPI>(`${this.dtApiEndpoint}/${threadId}`, {
                signal: this.discussionThreadAbortController.signal,
            });
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return null;
            } else {
                throw e;
            }
        }
    };

    private updateCaches = async (
        updaterFunction: (discussionThreads: IDiscussionThreadWithMessageIds[]) => IDiscussionThreadWithMessageIds[]
    ) => {
        const cacheIds = Object.values(FolderSelection)
            .filter(folder => folder !== FolderSelection.All)
            .map(folder => folder.toString());

        cacheIds.forEach(async cacheId => {
            const cachedData = await messagingHttpClientCached.storage.get(cacheId);
            if (cachedData.state !== 'cached') return;

            const discussionThreadData = cachedData.data?.data as PaginatedResponseAPI<SimpleDiscussionThreadAPI>;
            if (!discussionThreadData || !discussionThreadData.discussionThreads) return;

            const discussionThreads = updaterFunction(discussionThreadData.discussionThreads);
            const updatedCacheResponse = {
                ...cachedData,
                data: { ...cachedData.data, data: { ...(cachedData.data.data || {}), discussionThreads } },
            };
            await messagingHttpClientCached.storage.set(cacheId, updatedCacheResponse);
        });
    };

    setMessageAsRead = async (threadId: number, messageId?: number) => {
        const response = await messagingHttpClient.post<void>(`${this.dtApiEndpoint}/${threadId}/markread`, { messageId });
        if (response.status === 200) {
            this.updateCaches((discussionThreads: IDiscussionThreadWithMessageIds[]) => {
                discussionThreads.forEach(thread => {
                    if (thread.id === threadId) {
                        thread.lastReadMessageId = messageId ?? 0;
                    }
                });
                return discussionThreads;
            });
        }
        return response.status === 200;
    };
}

export default new DiscussionThreadService();
