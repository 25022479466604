import { ActionType } from '@root/context/ActionTypes';
import { MessagingState } from '@root/context/Context';
import React, {
    MutableRefObject,
    useEffect,
    forwardRef,
    PropsWithChildren,
    useRef,
    useImperativeHandle,
    useCallback,
} from 'react';
import { useInView } from 'react-intersection-observer';

// Inspired by https://github.com/Apestein/better-react-infinite-scroll and adapted parts from it
interface BiInfiniteScrollProps extends React.HTMLAttributes<HTMLDivElement> {
    fetchNextPage: () => Promise<unknown>;
    fetchPreviousPage: () => Promise<unknown>;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    loadingIndicator: React.ReactNode;
    scrollToBottom: boolean;
}

export const BiInfiniteScroller = forwardRef<HTMLDivElement, PropsWithChildren<BiInfiniteScrollProps>>(
    function BiInfiniteScroller(
        {
            fetchNextPage,
            fetchPreviousPage,
            hasNextPage,
            hasPreviousPage,
            loadingIndicator,
            children,
            scrollToBottom,
            ...props
        },
        forwardRef
    ) {
        const prevScrollHeight: MutableRefObject<number | null> = useRef<number>(null);
        const prevScrollTop: MutableRefObject<number | null> = useRef<number>(null);
        const prevAnchor: MutableRefObject<string | null> = useRef<string>(null);
        const {
            state: { scrollToBottomTrigger },
            dispatch,
        } = MessagingState();
        // React 19 will allow ref as prop, so refactor this when upgrading
        const ref = useRef<HTMLDivElement>(null);
        useImperativeHandle(forwardRef, () => ref.current as HTMLDivElement);
        const localPrevRef = useRef<HTMLDivElement | null>(null);
        const prevLoaderRef = useRef<HTMLDivElement | null>(null);
        const nextLoaderRef = useRef<HTMLDivElement | null>(null);

        useEffect(() => {
            if (scrollToBottom && ref.current && !prevScrollTop.current) {
                ref.current.scrollTop = ref.current.scrollHeight; //scroll to bottom on first render
            }
            if (ref.current && prevScrollHeight.current) {
                // Restore scroll position for backwards scroll to under the loading indicator
                const loaderContainerHeight = prevLoaderRef.current?.clientHeight ?? 0;
                ref.current.scrollTop = ref.current.scrollHeight - prevScrollHeight.current + loaderContainerHeight;
                // The previous loader might be hidden after fetching data, so show it again
                localPrevRef.current?.style.setProperty('display', 'block');
            }
            // Mentioned in https://github.com/Apestein/better-react-infinite-scroll/issues/16 with no fix
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [prevAnchor.current, scrollToBottom]);

        useEffect(() => {
            // Scrolls to bottom when another component triggers it. Refactor this BiInfiniteScroller is needed in multiple places
            if (ref.current && scrollToBottomTrigger) {
                const loaderContainerHeight = nextLoaderRef.current?.clientHeight ?? 0;
                ref.current.scrollTop = ref.current.scrollHeight - loaderContainerHeight;
                dispatch({
                    type: ActionType.SCROLL_MESSAGE_LIST_TO_BOTTOM,
                    payload: false,
                });
            }
        }, [scrollToBottomTrigger, dispatch]);

        const PreviousLoaderSection = () => {
            const { ref: prevRef, inView: prevInView } = useInView({
                initialInView: false,
                threshold: 1,
            });
            useEffect(() => {
                const loadOutsideOfView = async () => {
                    if (prevInView) {
                        if (ref.current) {
                            if (prevScrollTop.current && prevScrollHeight.current)
                                prevScrollTop.current += ref.current.scrollHeight - prevScrollHeight.current;
                            prevScrollHeight.current = ref.current.scrollHeight;
                        }
                        prevAnchor.current = window.crypto.randomUUID();
                        // Force previous loader to be hidden until fetch is done when user scrolls up fast
                        localPrevRef.current?.style.setProperty('display', 'none');
                        await fetchPreviousPage();
                    }
                };
                loadOutsideOfView();
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [prevInView]);

            const setPrevRefs = useCallback(
                (node: HTMLDivElement) => {
                    localPrevRef.current = node;
                    prevRef(node);
                },
                [prevRef]
            );

            return (
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        className="center-loading-ref"
                        ref={prevLoaderRef}>
                        {loadingIndicator}
                    </div>
                    <div
                        id="previous-loader"
                        data-testid="previous-loader"
                        className="center-loading-ref"
                        ref={setPrevRefs}
                    />
                </div>
            );
        };

        const NextLoaderSection = () => {
            const { ref: nextRef, inView: nextInView } = useInView({
                initialInView: false,
                threshold: 1,
            });
            const localNextRef = useRef<HTMLDivElement | null>(null);

            const setNextRefs = useCallback(
                (node: HTMLDivElement) => {
                    localNextRef.current = node;
                    nextRef(node);
                },
                [nextRef]
            );

            useEffect(() => {
                const loadOutsideOfView = async () => {
                    if (nextInView) {
                        if (ref.current) {
                            prevScrollTop.current = ref.current.scrollTop;
                            prevScrollHeight.current = ref.current.scrollHeight;
                        }
                        // Force next  loader to be hidden until fetch is done when user scrolls down fast
                        localNextRef.current?.style.setProperty('display', 'none');
                        // Try to adjust view to see the next message content
                        if (ref.current?.scrollTo) {
                            ref.current.scrollTo({
                                top: ref.current.scrollHeight + (nextLoaderRef.current?.clientHeight || 0),
                                behavior: 'smooth',
                            });
                        }
                        await fetchNextPage();
                        localNextRef.current?.style.setProperty('display', 'block');
                    }
                };
                loadOutsideOfView();
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [nextInView]);

            return (
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        id="next-loader"
                        data-testid="next-loader"
                        className="center-loading-ref"
                        ref={setNextRefs}
                    />
                    <div
                        className="center-loading-ref"
                        ref={nextLoaderRef}>
                        {loadingIndicator}
                    </div>
                </div>
            );
        };

        return (
            <div
                ref={ref}
                {...props}>
                {hasPreviousPage && <PreviousLoaderSection />}
                {children}
                {hasNextPage && <NextLoaderSection />}
            </div>
        );
    }
);
