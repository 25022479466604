// Vendors
import React from 'react';
import ReactDOM from 'react-dom/client';
// Context
import MessagingProvider from '@context/Context';
import { ToastContextProvider } from '@context/ToastContext';
import { FeatureFlagProvider } from '@context/FeatureFlagContext';
// Root component
import App from './App';
// Other
import './i18n';
import './styles/shared.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <React.Suspense>
            <FeatureFlagProvider>
                <MessagingProvider>
                    <ToastContextProvider>
                        <App />
                    </ToastContextProvider>
                </MessagingProvider>
            </FeatureFlagProvider>
        </React.Suspense>
    </React.StrictMode >
);