// Vendors
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Types
import { IRecord } from '@appTypes/recipients';
// Services
import RecipientService from '@services/RecipientService';
// Other
import { RecipientSelectionTypes } from './RecipientsPersonList';

interface IMyGuardiansCategoryButtonProps {
    guardianRecords: IRecord[];
    recipientType: RecipientSelectionTypes;
}

const MyGuardiansCategoryButton = ({ guardianRecords, recipientType }: IMyGuardiansCategoryButtonProps) => {
    const { t } = useTranslation();
    const { state: { activeButtonId }, dispatch } = MessagingState();

    const setActiveButtonId = (id: string) => {
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: id
        });
    };

    const onClickCategory = async (event: React.MouseEvent<HTMLButtonElement>, records: IRecord[]) => {
        event.preventDefault();
        if (recipientType === activeButtonId) {
            return;
        }

        // In case my teachers button was, clicked, we reset its fetching before
        // opening recipient person list.
        RecipientService.abortFetching();
        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: recipientType,
                records: records,
            }
        });

        setActiveButtonId(recipientType);
    };

    return (
        <button
            className={classNames('recipient-list-group-item-1', { 'active':  recipientType === activeButtonId })}
            onClick={(e) => {
                onClickCategory(e, guardianRecords);
            }}>
            <div className='recipient-list-text'>
                {recipientType === 'ownGuardians' ?
                    t('selectRecipients.ownGuardians') :
                    t('selectRecipients.otherGuardians')}
            </div>
        </button>
    );
};

export default MyGuardiansCategoryButton;