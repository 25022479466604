// Vendors
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
// Types
import { IUser } from '@appTypes/user';
import { IMessageWithContent } from '@appTypes/message';
// Other
import { getUserDisplayName } from '@common-utils';

interface Props {
    threadRecipients: IUser[];
    threadMessages: IMessageWithContent[];
    message: IMessageWithContent;
    index: number;
}

export const getAddedRecipients = (messageIndex: number, threadMessages: IMessageWithContent[], threadRecipients: IUser[], currentMessage: IMessageWithContent) => {
    // If message is the first one, everyone initially added can be ignored
    if (messageIndex === 0) return [];

    // Convert the message.sentAt to a timestamp value for easy comparison
    const messageTime = dayjs(currentMessage.sentAt);

    // Filter the recipients to find those added after the current message
    const addedRecipients = threadRecipients.filter((threadUser: IUser) => {
        // Convert the threadUser.addedAt to a timestamp value for easy comparison
        const addedAtDate = threadUser.addedAt ? dayjs(threadUser.addedAt) : 0;
        // Check if the recipient was added after the current message
        if (addedAtDate && addedAtDate.isAfter(messageTime)) {
            // Previous message is guaranteed when we skip first index
            const prevMessage = threadMessages[messageIndex - 1];
            const prevMessageTime = dayjs(prevMessage.sentAt);

            // Get the next message if it exists
            const nextMessage = threadMessages[messageIndex + 1];
            const nextMessageTime = nextMessage && dayjs(nextMessage.sentAt);

            // Check if the recipient's addedAtDate is within the range of the current message and the next message
            return addedAtDate.isAfter(prevMessageTime) && addedAtDate.isBefore(nextMessageTime);
        }
        // If the recipient was not added after the current message or it's the first message, exclude it
        return false;
    });
    return addedRecipients;
};

export const DiscussionThreadUserAdded = (props: Props) => {
    const {t} = useTranslation();

    return (
        <div>
            {/* Check for added recipients and render their messages */}
            {getAddedRecipients(props.index, props.threadMessages, props.threadRecipients, props.message).map((recipient: IUser) => (
                <div
                    key={`${recipient.roleGuid}`}
                    className='user-added-container'>
                    <p>{t('userAddedMessage', {fullName: `${getUserDisplayName(recipient)}`})}</p>
                </div>
            ))}
        </div>
    );
};
