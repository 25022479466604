// Vendors
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from 'react';
// Context
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Types
import { IModalRecipient } from '@appTypes/recipients';
// Components
import RecipientTabs from './RecipientTabs';
import RecipientOption from './RecipientOption';
import RecipientCategories from './RecipientCategories';
import RecipientsPersonList from './RecipientsPersonList';

const SelectRecipients = () => {
    const { state: { modalRecipients, modalHiddenRecipientLabels }, dispatch } = MessagingState();
    const recipientListRef = useRef<null | HTMLDivElement>(null);
    const [showMore, setShowMore] = useState(false);
    const toggleShowMore = () => {
        if(recipientListRef.current) {
            recipientListRef.current.scrollTop = 0;
        }
        setShowMore(!showMore);
    };
    const { t } = useTranslation();
    let extraContent = <></>;
    if (modalHiddenRecipientLabels.length > 0 || showMore) {
        extraContent = (
            <div className="row">
                <span>
                    <button
                        className='btn-link show-more-button btn'
                        onClick={toggleShowMore}>
                        { showMore ? t('buttons.showLess') : t('labels.showMoreCount', { count: modalHiddenRecipientLabels.length })}
                    </button>
                </span>
            </div>
        );
    }

    const removeStudent = (option: IModalRecipient) => {
    
        if (option === null || option.options.length === 0) {
            return null; 
        }
        const filteredRecipients = modalRecipients.modalRecipientOptions.filter(o => o.value !== option.value);
        dispatch({
            type: ActionType.SET_MODAL_RECIPIENTS,
            payload: { modalRecipientOptions: filteredRecipients }
        });
    };

    return (
        <div className='container-fluid full-width full-height list-group custom-bg'>
            <div className="selected-recipients-wrapper">
                <div className="row">
                    <div
                        id='select-recipients-header-row-label'
                        ref={recipientListRef}
                        className= {'col-sm' + classNames( { ' show-more': showMore })}>
                        <div id='recipients-label'>
                            {t('headers.recipients')}
                        </div>
                        {modalRecipients.modalRecipientOptions.length > 0 ? modalRecipients.modalRecipientOptions.map((option) => {
                            return (
                                <RecipientOption
                                    key={option.value}
                                    modalRecipient={option}
                                    removeStudent={() => removeStudent(option)}
                                />
                            );
                        }) :
                            <span id='chosen-recipient-placeholder'>{t('selectRecipients.chosenRecipientPlaceholder')}</span>
                        }
                    </div>
                </div>
                {extraContent}
                <div
                    className="row full-width">
                    <RecipientTabs />
                </div>
            </div>
            <div
                className="select-recipient-list">
                <div className="select-recipient-list group-item ">
                    <RecipientCategories />
                </div>
                <div
                    className="select-recipient-list group-item ">
                    <RecipientsPersonList />
                </div>
            </div>
        </div>
    );
};

export default SelectRecipients;
