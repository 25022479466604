// Vendors
import React, { useCallback, useEffect, useRef } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
// Context
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Types
import { IDiscussionThreadWithMessageIds } from '@root/@types/discussionthread';
import { IMessageWithContent } from '../@types/message';
// Services
import DiscussionThreadService from '@services/DiscussionThreadService';
// Hooks
import { useUpdateInboxUnreadCount } from '@hooks/useUpdateInboxUnreadCount';
import { useUpdateMessageReadStatus } from '@hooks/useUpdateMessageReadStatus';
// Other
import { getThreadUnreadMessages } from '@common-utils';
import Message from '@root/components/Message';
import { LoadingState } from '@root/@types/loadingstates';
import { BiInfiniteScroller } from '@root/components/InfiniteScroller';
import { Spinner } from '@vismaux/react-vud';
import { useToast } from '@root/context/ToastContext';
import { useTranslation } from 'react-i18next';
import { PagedParametersAPI } from '@root/@types/API/discussionThreadAPI';

const MessageList: React.FC = () => {
    const {
        state: { activeDiscussionThread, activeThreadMessages },
        dispatch,
    } = MessagingState();
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();
    const { updateMessageReadStatus } = useUpdateMessageReadStatus();
    //TODO zero until pagination canReplyToThisMessage solution is found, currently frontend only supports one page of messages
    const threadId = activeDiscussionThread?.id;
    const hasUnreadMessages =
        (activeDiscussionThread &&
            activeThreadMessages &&
            getThreadUnreadMessages(activeThreadMessages, activeDiscussionThread.lastReadMessageId).length > 0) ||
        false;
    const messageListRef = useRef<HTMLDivElement>(null);
    const { createToast } = useToast();
    const { t } = useTranslation();

    useEffect(() => {
        if (!threadId || !activeThreadMessages || !activeDiscussionThread) {
            return;
        }
        const latestMessage = activeThreadMessages.slice(-1)[0];
        const unreadMessages = activeDiscussionThread.lastReadMessageId
            ? getThreadUnreadMessages(activeDiscussionThread.messages, activeDiscussionThread.lastReadMessageId)
            : activeDiscussionThread.messages.map(m => m.id);
        if (unreadMessages.includes(latestMessage.id)) {
            updateMessageReadStatus(threadId, latestMessage.id).then(() => {
                updateInboxUnreadCount();
            });
        }
        amplitude.track('message_opened', {
            discussion_thread_id: threadId,
        });
    }, [dispatch, threadId, activeDiscussionThread, updateInboxUnreadCount, activeThreadMessages, updateMessageReadStatus]);

    const currentNewestMessageIndex = useCallback(
        (threadMessages: IMessageWithContent[], thread: IDiscussionThreadWithMessageIds) => {
            const currentNewestLoadedMessageId = threadMessages[threadMessages.length - 1].id;
            return thread.messages.findIndex(m => m.id === currentNewestLoadedMessageId);
        },
        []
    );

    const hasNext = useCallback(
        (threadMessages: IMessageWithContent[], thread: IDiscussionThreadWithMessageIds) => {
            return currentNewestMessageIndex(threadMessages, thread) !== thread.messages.length - 1;
        },
        [currentNewestMessageIndex]
    );

    const currentOldestMessageIndex = useCallback(
        (threadMessages: IMessageWithContent[], thread: IDiscussionThreadWithMessageIds) => {
            const currentOldestLoadedMessageId = threadMessages[0].id;
            return thread.messages.findIndex(m => m.id === currentOldestLoadedMessageId);
        },
        []
    );

    const hasPrevious = useCallback(
        (threadMessages: IMessageWithContent[], thread: IDiscussionThreadWithMessageIds) => {
            return currentOldestMessageIndex(threadMessages, thread) !== 0;
        },
        [currentOldestMessageIndex]
    );

    const fetchNewerMessages = useCallback(async () => {
        if (!activeDiscussionThread || !activeThreadMessages) return [];
        if (!hasNext(activeThreadMessages, activeDiscussionThread)) {
            return;
        }
        const newestMessageIndex = currentNewestMessageIndex(activeThreadMessages, activeDiscussionThread);
        const newerMessageIdIndex = Math.min(activeDiscussionThread.messages.length - 1, newestMessageIndex + 6);
        let fetchMessageIds = activeThreadMessages.map(m => m.id);
        fetchMessageIds = fetchMessageIds.concat(
            activeDiscussionThread.messages.slice(newestMessageIndex + 1, newerMessageIdIndex + 1).map(m => m.id)
        );
        try {
            const pagedPayload: PagedParametersAPI = {
                pageNumber: 1,
                pageSize: 0,
            };
            const messageResponse = await DiscussionThreadService.postThreadMessages(
                activeDiscussionThread.id,
                { messageIds: fetchMessageIds },
                pagedPayload
            );
            dispatch({
                type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                payload: {
                    thread: activeDiscussionThread,
                    activeThreadMessages: messageResponse.messages,
                    loadingState: LoadingState.Done,
                },
            });
        } catch {
            console.error('Error fetching newer messages');
            createToast({
                title: t('errors.messageLoadingError'),
                toastType: 'danger',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDiscussionThread, activeThreadMessages, hasNext, currentNewestMessageIndex, dispatch]);

    const fetchOlderMessages = useCallback(async () => {
        if (!activeDiscussionThread || !activeThreadMessages) return [];
        if (!hasPrevious(activeThreadMessages, activeDiscussionThread)) {
            return;
        }
        const oldestMessageIndex = currentOldestMessageIndex(activeThreadMessages, activeDiscussionThread);
        const olderMessageIdIndex = Math.max(0, oldestMessageIndex - 6);
        const olderFetchMessageIds = activeDiscussionThread.messages
            .slice(olderMessageIdIndex, oldestMessageIndex)
            .map(m => m.id);
        const fetchMessageIds = olderFetchMessageIds.concat(activeThreadMessages.map(m => m.id));
        try {
            const pagedPayload: PagedParametersAPI = {
                pageNumber: 1,
                pageSize: 0,
            };
            const messageResponse = await DiscussionThreadService.postThreadMessages(
                activeDiscussionThread.id,
                { messageIds: fetchMessageIds },
                pagedPayload
            );
            dispatch({
                type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                payload: {
                    thread: activeDiscussionThread,
                    activeThreadMessages: messageResponse.messages,
                    loadingState: LoadingState.Done,
                },
            });
        } catch {
            console.error('Error fetching older messages');
            createToast({
                title: t('errors.messageLoadingError'),
                toastType: 'danger',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDiscussionThread, activeThreadMessages, currentOldestMessageIndex, dispatch, hasPrevious]);

    const loadingElement = () => {
        return (
            <div className="message-loading-indicator">
                <Spinner />
            </div>
        );
    };

    return (
        activeDiscussionThread &&
        activeThreadMessages && (
            <BiInfiniteScroller
                id={'message-list'}
                className="message-list"
                ref={messageListRef}
                tabIndex={0}
                fetchNextPage={fetchNewerMessages}
                fetchPreviousPage={fetchOlderMessages}
                hasNextPage={hasNext(activeThreadMessages, activeDiscussionThread)}
                hasPreviousPage={hasPrevious(activeThreadMessages, activeDiscussionThread)}
                loadingIndicator={loadingElement()}
                scrollToBottom={!hasUnreadMessages}>
                {activeThreadMessages?.map(message => (
                    <Message
                        threadMessages={activeThreadMessages}
                        message={message}
                        key={message.id}
                        activeDiscussionThread={activeDiscussionThread}
                    />
                ))}
            </BiInfiniteScroller>
        )
    );
};

export default MessageList;
