import { useRef, useEffect } from 'react';

export default (callback: (...args: unknown[]) => void, delay: number) => {
    const timeoutRef = useRef<number | undefined>(undefined);
    const debouncedCallback = (...args: unknown[]) => {
        if (timeoutRef.current !== undefined) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = window.setTimeout(() => {
            callback(...args);
        }, delay);
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current !== undefined) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return debouncedCallback;
};
