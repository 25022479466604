// Vendors
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
// Context
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Types
import { IModalRecipient } from '@appTypes/recipients';
// Other
import { getLabel } from '@common-utils';

interface IRecipientOptionProps {
    modalRecipient: IModalRecipient;
    removeStudent: (modalRecipient: IModalRecipient) => void;
}

const RecipientOption = ({modalRecipient, removeStudent}: IRecipientOptionProps ) => {
    const { dispatch } = MessagingState();
    const { ref, inView } = useInView({
        initialInView: true, // Prevent initial flickering with "show more" button
    });

    useEffect(() => {
        dispatch({
            type: ActionType.MODAL_IS_RECIPIENT_IN_VIEW,
            payload: { value: modalRecipient.value, inView }
        });
    }, [inView, dispatch, modalRecipient.value]);

    return (
        <div
            ref={ref}
            className={classNames('d-flex recipient-label')}>
            <span
                className='label-name'
                tabIndex={!inView ? -1 : 0}>
                {getLabel(modalRecipient)}
            </span>
            <button
                className='remove-button'
                tabIndex={!inView ? -1 : 0}
                onClick={() => removeStudent(modalRecipient)}>X
            </button>
        </div>

    );
};

export default RecipientOption;
