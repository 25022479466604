// Vendors
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Icon, Spinner } from '@vismaux/react-vud';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
// Context
import { useToast } from '@context/ToastContext';
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Types
import { LoadingState } from '@appTypes/loadingstates';
import { IDiscussionThread, IDiscussionThreadWithMessageIds, IPagedResponsePayload } from '@appTypes/discussionthread';
// Services
import DiscussionThreadService from '@services/DiscussionThreadService';
// Components
import Paginate from './Paginate';
import WilmaNavLink from './WilmaReactRouter/WilmaNavLink';
import { DiscussionThreadListItem } from './DiscussionThreadListItem';
// Other
import { getThreadUnreadMessages } from '../utils/utils';

const ITEMS_PER_PAGE = 10;

const DiscussionThreadPagination = () => {
    const { t } = useTranslation();
    const { state: { discussionThreads, activeDiscussionThread, paginationData, discussionThreadsLoading }, dispatch } = MessagingState();
    const toast = useToast();
    const { folderParam, pageNumber } = useParams();
    const [activePage, setActivePage] = useState( pageNumber ? +pageNumber : 1);
    const location = useLocation();
    const navigate = useNavigate();
    
    // TODO to be fixed later? if opening a link with a page number and thread, like messaging/sent/page/3/2556, and there have been new threads and the thread 2556 is on a different page than before, page will be opened incorrectly

    useEffect(() => {
        const fetchData = async (currentPage: number) => {
            const pageNumberInt = pageNumber ? +pageNumber : 1;
            if (!folderParam) return;

            let response: IPagedResponsePayload = {discussionThreads: [], paginationData: {
                totalPages: 1,
                currentPage: 0,
                pageSize: 0,
                totalCount: 0
            }};
            try {
                dispatch({
                    type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                    payload: LoadingState.Loading
                });
                response = await fetchFolderThreadsFunction(folderParam).call(this, { pageNumber: pageNumberInt, pageSize: ITEMS_PER_PAGE}) ?? response;

                if (response.paginationData.currentPage !== 0) {
                    if (response.paginationData.totalPages != 0 && response.paginationData.totalPages < pageNumberInt) {
                        response = await fetchFolderThreadsFunction(folderParam).call(this, { pageNumber: 1, pageSize: ITEMS_PER_PAGE}) ?? response;
                        navigate(`/${folderParam}/page/1?${location.search}`);
                    }
                    dispatch({
                        type: ActionType.SET_DISCUSSIONTHREADS,
                        payload: response.discussionThreads
                    });
                    dispatch({
                        type: ActionType.SET_PAGINATION_DATA,
                        payload: { currentPage: response.paginationData.currentPage, totalPages: response.paginationData.totalPages }
                    });
                    dispatch({
                        type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                        payload: LoadingState.Done
                    });
                    setActivePage(currentPage);
                }
            } catch {
                toast.createToast({
                    title: t('errors.messageLoadingError'),
                    toastType: 'danger'
                });
                dispatch({
                    type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                    payload: LoadingState.Error
                });
            }
        };
        fetchData(activePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folderParam, activePage, dispatch]);

    const fetchFolderThreadsFunction = (folderParam: string) => {
        switch (folderParam) {
            case 'received':
                return DiscussionThreadService.getReceived;
            case 'sent':
                return DiscussionThreadService.getSent;
            default:
                throw new Error(`Invalid folderParam: ${folderParam}`);
        }
    };

    const handlePageClick = (event: { selected: number; }) => {
        const newPageNumber = event.selected + 1;
        setActivePage(newPageNumber);
        navigate(location.pathname.replace(/\/page\/\d+/, `/page/${newPageNumber}${location.search}`));
    };
  
    return (
        <>
            {discussionThreadsLoading === LoadingState.Loading && (
                <li className='center'>
                    <Spinner />
                </li>
            )}
            {discussionThreadsLoading === LoadingState.Error && (
                <li className='center'>
                    <Icon
                        name='error'
                        size='sm' />
                    <span className='error-text'>{t('errors.discussionThreadLoadingFailed')}</span>
                </li>
            )}
            {discussionThreadsLoading === LoadingState.Done &&
                    discussionThreads?.map((discussionThread: IDiscussionThread | IDiscussionThreadWithMessageIds) => {
                        const unreadMessageCount = getThreadUnreadMessages(discussionThread.messages, discussionThread.lastReadMessageId).length;
                        const discussionThreadListItemWrapperClass = classNames('list-group-item',
                            { active: activeDiscussionThread?.id === discussionThread.id },
                            { 'isUnreadThread': unreadMessageCount > 0 });
                        return (
                            <WilmaNavLink
                                key={discussionThread.id}
                                className={discussionThreadListItemWrapperClass}
                                to={`${discussionThread.id}`}
                                aria-label={activeDiscussionThread?.id === discussionThread.id ? t('chosenMessage') : ''}
                                id={`discussionThread${discussionThread.id}`}
                                tabIndex={0}
                                relative='path'
                            >
                                {<DiscussionThreadListItem discussionThread={discussionThread} />}
                            </WilmaNavLink>
                        );
                    })
            }
            <li className='list-group-item list-group-pagination'>
                <Paginate
                    currentPage={paginationData.currentPage}
                    totalPages={paginationData.totalPages}
                    handlePageClick={handlePageClick} />
            </li>

        </>
    );
};

export default DiscussionThreadPagination;