// Vendors
import React from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Other
import { StringOption } from './WilmaAsyncSelect';
import { getUserDisplayName } from '@common-utils';

type Props = {
    recipientList: StringOption[],
    shown: boolean;
    hideRemoveButton?: boolean;
    readonlyUsers?: string[];
    close: () => void;
}

const ShowMoreModal = ({ shown, close, hideRemoveButton, recipientList, readonlyUsers }: Props) => {
    const { t } = useTranslation();
    const {dispatch } = MessagingState();

    const removeRecipient = (roleGuid: string) => {
        const selectedRecipients = recipientList.filter(value => value.value !== roleGuid) || [];
        dispatch({
            type: ActionType.SET_SELECTED_RECIPIENTS, payload: { options: selectedRecipients }
        });
    };

    const closeModal = () => {
        close();
    };

    return shown ? (
        <div
            className="modal-backdrop"
            //close modal when clicking outside modal content
            onClick={closeModal}
        >
            <div
                className="recipient-modal-content container-fluid"
                onClick={e => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}
            >
                <div className='row recipient-row'>
                    <div id='recipient-container'>
                        {recipientList.map(recipient => (
                            <div
                                key={`recipient-element-${recipient.value}`}
                                className='recipient-label'>
                                <div className='recipient-name'>
                                    {getUserDisplayName({ roleGuid: recipient.value, label: recipient.label, roleType: recipient.roleType})}
                                </div>
                                {!hideRemoveButton && !readonlyUsers?.includes(recipient.value) &&(
                                    <div
                                        role='button'
                                        className='remove-button'
                                        onClick={() => removeRecipient(recipient.value)}>
                                        x
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='row modal-close'>
                    <button
                        className="btn btn-primary bg-secondary action-button"
                        onClick={closeModal}>{t('buttons.close')}
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default ShowMoreModal;