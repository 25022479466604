// Vendors
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Icon } from '@vismaux/react-vud';
import { useLocation, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
// Types
import { LoadingState } from '@appTypes/loadingstates';
import { ISearchResult } from '@root/@types/search';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Services
import DiscussionThreadService from '../services/DiscussionThreadService';
import FolderService, { FolderSelection } from '../services/FolderService';
// Components
import Paginate from './Paginate';
import WilmaNavLink from './WilmaReactRouter/WilmaNavLink';
// Other
import { DiscussionThreadListItem } from './DiscussionThreadListItem';
import { getToThreadNavigationPath } from '@common-utils';

interface ISearchResultPaginationProps {
    searchText: string;
    folderSelection: FolderSelection;
}

const ITEMS_PER_PAGE = 10;

const SearchResultPagination = ({ searchText, folderSelection }: ISearchResultPaginationProps) => {
    const { t } = useTranslation();
    const {
        state: { searchPayload, paginationData, searchResponses, activeDiscussionThread, discussionThreadsLoading },
        dispatch,
    } = MessagingState();

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const allFolders = FolderService.getAll();

    const getSanitizedUrlSearchParams = () => {
        return {
            searchText: searchParams.get('searchText') ?? '',
            folderSelection: searchParams.get('folderSelection') ? Number(searchParams.get('folderSelection')) : 0,
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        };
    };

    const getSelectedFolder = (selectedFolderId: number) => {
        if (allFolders.length === 0) {
            return FolderSelection.Received;
        }
        return allFolders.find(folder => folder.id === selectedFolderId)?.folderSelection ?? allFolders[0].folderSelection;
    };

    const sanitizedSearchParams = getSanitizedUrlSearchParams();
    const pageParam = sanitizedSearchParams.page;
    const searchTextParam = sanitizedSearchParams.searchText;
    const selectedFolder = getSelectedFolder(sanitizedSearchParams.folderSelection);

    const fetchData = async (searchText: string, folderSelection: FolderSelection, pageNumber: number) => {
        dispatch({
            type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
            payload: LoadingState.Loading,
        });
        try {
            const response = await DiscussionThreadService.search({
                searchText,
                folderSelection,
                pageNumber,
                pageSize: ITEMS_PER_PAGE,
            });
            if (response.paginationData.currentPage !== 0) {
                dispatch({
                    type: ActionType.SET_SEARCH_DISCUSSIONTHREADS,
                    payload: response.discussionThreads,
                });
                dispatch({
                    type: ActionType.SET_PAGINATION_DATA,
                    payload: {
                        currentPage: response.paginationData.currentPage,
                        totalPages: response.paginationData.totalPages,
                    },
                });
                dispatch({
                    type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                    payload: LoadingState.Done,
                });
            }
        } catch (error) {
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Error,
            });
            console.error('Failed to fetch discussion threads:', error);
        }
    };

    useEffect(() => {
        const fetchDataAtReload = async (searchText: string, folder: FolderSelection, page: number) => {
            await fetchData(searchText, folder, page);
        };

        if (location.search.includes('search') && searchResponses === null) {
            const { searchText, folderSelection, page } = getSanitizedUrlSearchParams();
            const selectedFolder = getSelectedFolder(folderSelection);
            fetchDataAtReload(searchText, selectedFolder, page);
            navigate({
                pathname: location.pathname,
                search: `search&${createSearchParams({
                    searchText,
                    folderSelection: folderSelection.toString(),
                    page: page.toString(),
                }).toString()}
                `,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, searchResponses, dispatch, searchTextParam, selectedFolder, pageParam]);

    const handlePagination = (event: { selected: number }) => {
        const nextPage = event.selected + 1;
        navigate({
            pathname: location.pathname,
            search: `search&${createSearchParams({
                searchText: searchPayload.searchText,
                folderSelection: searchPayload.folder.folderSelection.toString(),
                page: nextPage.toString(),
            }).toString()}`,
        });
        fetchData(searchText, folderSelection, nextPage);
    };

    const createLoadingStateElement = (loadingState: LoadingState) => {
        let output = null;
        switch (loadingState) {
            case LoadingState.Loading:
                output = <Spinner />;
                break;
            case LoadingState.Error:
                output = (
                    <>
                        <Icon
                            name="error"
                            size="sm"
                        />
                        <span className="error-text">{t('errors.discussionThreadSearchFailed')}</span>
                    </>
                );
                break;
            default:
                output = null;
        }
        return <li className="center">{output}</li>;
    };

    /**
     * Turns IDiscussionThreadListItems into a clickable WilmaNavLink-component with DiscussionThreadListItem-component inside.
     * The link will append the thread's id to the path and the current search parameters to the query string.
     */
    const createDiscussionThreadLinkElement = (searchResponse: ISearchResult) => {
        const className = classNames(
            'list-group-item',
            { active: activeDiscussionThread?.id === searchResponse.discussionThread.id },
            {
                isUnreadThread:
                    searchResponse.discussionThread.latestMessageId > searchResponse.discussionThread.lastReadMessageId,
            }
        );
        const { searchText, folder } = searchPayload;
        const queryString = `search&${createSearchParams({
            searchText: searchText,
            folderSelection: folder.folderSelection.toString(),
            page: pageParam.toString(),
        }).toString()}
        `;
        return (
            <WilmaNavLink
                key={searchResponse.discussionThread.id}
                aria-label={activeDiscussionThread?.id === searchResponse.discussionThread.id ? t('chosenMessage') : ''}
                className={className}
                to={`${getToThreadNavigationPath(location.pathname, searchResponse.discussionThread.id.toString())}?${queryString}`}
                tabIndex={0}
                relative="path">
                {
                    <DiscussionThreadListItem
                        discussionThread={searchResponse.discussionThread}
                        foundInFolder={searchResponse.foundInFolder}
                        isActive={activeDiscussionThread?.id === searchResponse.discussionThread.id}
                        isUnread={
                            searchResponse.discussionThread.latestMessageId >
                            searchResponse.discussionThread.lastReadMessageId
                        }
                    />
                }
            </WilmaNavLink>
        );
    };

    return (
        <>
            {discussionThreadsLoading !== LoadingState.Done
                ? createLoadingStateElement(discussionThreadsLoading)
                : searchResponses?.map(searchResponse => createDiscussionThreadLinkElement(searchResponse))}
            <li className="list-group-item list-group-pagination">
                <Paginate
                    currentPage={paginationData.currentPage}
                    totalPages={paginationData.totalPages}
                    handlePageClick={handlePagination}
                />
            </li>
        </>
    );
};

export default SearchResultPagination;
