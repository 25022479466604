import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
// Context
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Services
import DiscussionThreadService from '@services/DiscussionThreadService';

export const useUpdateMessageReadStatus = () => {
    const {
        state: { activeDiscussionThread },
        dispatch,
    } = MessagingState();
    const location = useLocation();

    const updateMessageReadStatus = useCallback(
        async (threadId?: number, latestMessageId?: number) => {
            // Thread to be read or unread is either the one that has the clicked mail icon (threadId is provided) or active thread.
            const threadIdToUse = threadId || (activeDiscussionThread && activeDiscussionThread.id);
            if (!threadIdToUse) {
                return;
            }
            try {
                // If latestMessageId is provided, set that message as read, othwerwise set the whole thread as unread
                const response = await DiscussionThreadService.setMessageAsRead(threadIdToUse, latestMessageId);
                if (!response) {
                    console.error('Failed to mark message as read');
                    return;
                }
                const isSearch = location.search.includes('search');
                if (latestMessageId) {
                    dispatch({
                        type: isSearch
                            ? ActionType.SET_SEARCHED_THREAD_MESSAGE_AS_READ_SUCCESS
                            : ActionType.SET_MESSAGE_AS_READ_SUCCESS,
                        payload: {
                            threadId: threadIdToUse,
                            messageId: latestMessageId,
                        },
                    });
                } else {
                    dispatch({
                        type: isSearch
                            ? ActionType.SET_SEARCHED_THREAD_AS_UNREAD_SUCCESS
                            : ActionType.SET_THREAD_AS_UNREAD_SUCCESS,
                        payload: {
                            threadId: threadIdToUse,
                        },
                    });
                }
            } catch (e) {
                console.error('Failed to mark message as read', e);
            }
        },
        [activeDiscussionThread, dispatch, location.search]
    );

    return { updateMessageReadStatus };
};
