// Vendors
import { AxiosResponse, CanceledError } from 'axios';
// Types
import { LoadingState } from '@appTypes/loadingstates';
import {
    IRecord,
    IRecords,
    IGradebook,
    IRecipientId,
    IIndexRecords,
    ITeacherRecords,
    IStudentRecords,
    IPrimusSearches,
    IPersonnelRecords,
    IWorkplaceInstructorRecords,
    ITrainingCoordinatorRecords
} from '@appTypes/recipients';
// Other
import { messagingHttpClient } from '@root/http-common';

class RecipientService {
    private abortController = new AbortController();
    private _loadingState: LoadingState = LoadingState.Loading;
    private _primusSearchLoadingState: LoadingState = LoadingState.Loading;

    public get loadingState(): LoadingState {
        return this._loadingState;
    }

    public get primusSearchLoadingState(): LoadingState {
        return this._primusSearchLoadingState;
    }

    public abortFetching() {
        this.abortController.abort();
        this.abortController = new AbortController();
        this._loadingState = LoadingState.Loading;
    }

    public abortFetchingIndexCategory() {
        this.abortController.abort();
        this.abortController = new AbortController();
        this._loadingState = LoadingState.Loading;
        this._primusSearchLoadingState = LoadingState.Done;
    }

    searchRecipients(searchText: string) {
        return messagingHttpClient.get<IRecords>('/ms/messaging/recipient/search?searchValue=' + searchText);
    }

    async getRecipients(): Promise<IIndexRecords> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<IIndexRecords>('/ms/messaging/recipient', { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return { indexRecords: [] };
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getOwnTeachers(): Promise<ITeacherRecords> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<ITeacherRecords>('/ms/messaging/recipient/ownteachers/', { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return { teacherRecords: [] };
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getRecipientsByClass(classId: string): Promise<IStudentRecords> {
        try {
            this.abortFetchingIndexCategory();
            const response = await messagingHttpClient.get<IStudentRecords>('/ms/messaging/recipient/class/' + classId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return { studentRecords: [] };
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async postRecipientsInfo(recipientIds: IRecipientId[]): Promise<AxiosResponse<IRecord[]>> {
        return await messagingHttpClient.post('/ms/messaging/recipient/recipientinfo', recipientIds);
    }

    async getCurrentGradebooks(): Promise<IGradebook> {
        return (await messagingHttpClient.get<IGradebook>('/ms/messaging/recipient/groups/current')).data;
    }

    async getPastGradebooks(): Promise<IGradebook> {
        return (await messagingHttpClient.get<IGradebook>('/ms/messaging/recipient/groups/past')).data;
    }

    async getFutureGradebooks(): Promise<IGradebook> {
        return (await messagingHttpClient.get<IGradebook>('/ms/messaging/recipient/groups/future')).data;
    }

    async getRecipientsByGroup(groupId: string): Promise<IStudentRecords> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<IStudentRecords>('/ms/messaging/recipient/group/' + groupId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return { studentRecords: [] };
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getTeachersInSchool(schoolId: string): Promise<IRecord[]> {
        try {
            this.abortFetchingIndexCategory();
            const response = await messagingHttpClient.get<ITeacherRecords>('/ms/messaging/recipient/teachers/' + schoolId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data.teacherRecords;
        } catch (e) {
            if (e instanceof CanceledError) {
                return [];
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getPersonnelInSchool(schoolId: string): Promise<IRecord[]> {
        try {
            this.abortFetchingIndexCategory();
            const response = await messagingHttpClient.get<IPersonnelRecords>('/ms/messaging/recipient/personnel/' + schoolId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data.personnelRecords;
        } catch (e) {
            if (e instanceof CanceledError) {
                return [];
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getWorkplaceInstructorsInSchool(schoolId: string): Promise<IRecord[]> {
        try {
            this.abortFetchingIndexCategory();
            const response = await messagingHttpClient.get<IWorkplaceInstructorRecords>('/ms/messaging/recipient/workplaceinstructor/' + schoolId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data.workplaceInstructorRecords;
        } catch (e) {
            if (e instanceof CanceledError) {
                return [];
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getTrainingCoordinatorsInSchool(schoolId: string): Promise<IRecord[]> {
        try {
            this.abortFetchingIndexCategory();
            const response = await messagingHttpClient.get<ITrainingCoordinatorRecords>('/ms/messaging/recipient/trainingcoordinator/' + schoolId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data.trainingCoordinatorRecords;
        } catch (e) {
            if (e instanceof CanceledError) {
                return [];
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getPrimusSearches(schoolId: string): Promise<IPrimusSearches> {
        try {
            this.abortFetching();
            this._primusSearchLoadingState = LoadingState.Loading;
            const response = await messagingHttpClient.get<IPrimusSearches>('/ms/messaging/recipient/primussearches/' + schoolId, { signal: this.abortController.signal });
            this._primusSearchLoadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    studentRecords: [],
                    teacherRecords: [],
                    personnelRecords: [],
                    workplaceInstructorRecords: [],
                    trainingCoordinatorRecords: []
                };
            } else {
                this._primusSearchLoadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getPrimusSearchRecords<T>(endpoint: string, schoolId: string, searchId: string, defaultReturn: T): Promise<T> {
        try {
            this.abortFetchingIndexCategory();
            const response = await messagingHttpClient.get<T>('/ms/messaging/recipient/primussearches/' + endpoint + '/' + schoolId + '/' + searchId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return defaultReturn;
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }
}

export default new RecipientService();
